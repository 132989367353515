import * as React from 'react';
import TextField from '@mui/material/TextField';
import Prototypes from 'prop-types';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { get } from 'react-hook-form';

export default function TuiDateTimePicker({
    id,
    label,
    name,
    InputLabelProps,
    helperText = '',
    errors,
    required = true,
    size = 'medium',
    ...rest
}) {
    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <MobileDateTimePicker
                id={id}
                label={label}
                format='DD MMM YYYY hh:mm A'
                slotProps={{
                    textField: {
                        fullWidth: true,
                        helperText: get(errors, name)?.message || helperText,
                        error: get(errors, name),
                        size,
                        required
                    },
                }}
                renderInput={(params) => <TextField
                    {...params}
                    InputLabelProps={{
                        ...InputLabelProps,
                        shrink: true,
                    }}
                    fullWidth
                    label={label}
                    InputProps={{
                        ...params.InputProps,
                    }}
                />}
                {...rest}
            />
        </LocalizationProvider>
    );
}

TuiDateTimePicker.prototypes = {
    id: Prototypes.string.isRequired,
    label: Prototypes.string.isRequired,
    name: Prototypes.string.isRequired,
    required: Prototypes.bool.isRequired,
    options: Prototypes.array.isRequired,
};
