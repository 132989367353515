import React, { useState, useEffect } from 'react';
import {
    Grid2 as Grid,
    Typography,
    IconButton,
    Tooltip,
    ListItemIcon,
    MenuItem,
    Menu,
    Box
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import RefreshIcon from '@mui/icons-material/Refresh';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import MessageIcon from '@mui/icons-material/Message';
import MoveUpOutlinedIcon from '@mui/icons-material/MoveUpOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIconOutlined from '@mui/icons-material/EditOutlined';
import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined';
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import DinespotLogo from '../../../assets/images/dinespot_black_logo.png';
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable';
import { TuiSpinner, CoreButton, Hooks, ConfirmationModal, DataNotFound, TuiAutoComplete, TuiAppBar, Table, Footer } from '../../../core';
import { stopsApi, deliveryRoutesApi } from '../../../js/slices/api_slices';
import MapViewDrawer from '../components/map_view_drawer';
import { getFormattedTimeFromUnixTimestamp, getFormattedDateFromUnixTimestamp } from '../../../js/lib/utils';
import { TIFFIN } from '../../../js/lib/constants';
import { getStopStatusChip } from '../routes_utils';

const { useSnackBarNotification } = Hooks;
const { useLazyGetStopsQuery, useLazyGetStopQuery, useUpdateStopMutation } = stopsApi;
const { useGetDeliveryRouteQuery } = deliveryRoutesApi

const headCells = [{
    id: 'stop',
    title: 'Stop',
}, {
    id: 'name',
    title: 'Name',
}, {
    id: 'mealPlan',
    title: 'Meal Plan',
}, {
    id: 'address',
    title: 'Address',
}, {
    id: 'status',
    title: 'Status',
}, {
    id: 'deliveryTime',
    title: 'Delivery Time',
}, {
    id: 'image',
    title: 'Image',
}, {
    id: 'businessNote',
    title: 'Business Note',
}, {
    id: 'customerNote',
    title: 'Customer Note',
},
    // {
    //     id: 'action',
    //     title: 'Actions',
    // }
];

export default function RouteStopsView() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [fetchLoading, setFetchLoading] = useState(false);
    const [trigger] = useLazyGetStopsQuery({});
    const [showMapDrawer, setShowMapDrawer] = useState(false);
    const [stops, setStops] = useState([]);
    const [processedStops, setProcessedStops] = useState([]);
    const [useGetStopQueryTrigger, { isLoading }] = useLazyGetStopQuery();
    const { errorSnackBar, successSnackBar } = useSnackBarNotification();
    const [selectedItem, setSelectedItem] = useState(null);
    const [downloadLoading, setDownloadLoading] = useState(false);
    const [showMoveConfirmationModal, setShowMoveConfirmationModal] = useState(false);
    const [showCancelConfirmationModal, setShowCancelConfirmationModal] = useState(false);
    const [showActiveConfirmationModal, setShowActiveConfirmationModal] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget); // Set anchorEl to the button element
    };

    const handleClose = (e) => {
        setAnchorEl(null);
        e.stopPropagation();
    };

    const { data: routeDetails, isSuccess: routeDetailsLoading } = useGetDeliveryRouteQuery({
        id,
    }, { refetchOnMountOrArgChange: true, skip: !id });

    const fetchAllPages = async (page = 1, pageSize = 200) => {
        const response = await trigger({
            'page': page,
            'pageSize': pageSize,
            'routeId': id,
            'sortKey': '+position',
        });
        let data = response?.data?.data;
        const totalCount = data.totalCount;
        const totalPages = Math.ceil(totalCount / pageSize);

        if (page < totalPages) {
            const nextPageData = await fetchAllPages(page + 1, pageSize);
            data = { ...data, items: [...data.items, ...nextPageData.items] };
        }

        return data;
    };

    function updateStopsInList(stops) {
        if (stops?.length) {
            const processedSubscribers = [];
            for (const stop of stops) {
                const { position, customer, address, status, imageName, noteForBusiness, noteForCustomer, id, events } = stop;
                processedSubscribers.push({
                    position,
                    name: `${customer?.firstName || ''} ${customer?.lastName || ''}`,
                    mealPlan: customer?.tiffin?.name,
                    address,
                    status,
                    deliveryTime: events?.finish ? getFormattedTimeFromUnixTimestamp(events?.finish) : '--',
                    imageName,
                    noteForBusiness,
                    noteForCustomer,
                    phoneNumber: customer?.phoneNumber,
                    id,
                });
            }
            setStops(stops);
            setProcessedStops(processedSubscribers);
        }
    }

    const fetchAllStops = async () => {
        setFetchLoading(true);
        let stops = await fetchAllPages();
        updateStopsInList(stops?.items);
        setFetchLoading(false);
    };

    const [updateStop, {
        isSuccess: updateStopIsSuccess,
        error: updateStopError,
        isError: updateStopIsError,
        isLoading: updateStopIsLoading,
    }] = useUpdateStopMutation();

    useEffect(() => {
        if (id) {
            fetchAllStops()
        }
    }, [id, updateStopIsSuccess]);

    useEffect(() => {
        if (updateStopIsSuccess) {
            successSnackBar({ message: 'Stop updated successfully' });
        } if (updateStopError && updateStopIsError) {
            errorSnackBar({ message: updateStopError?.data?.errorMessage });
        }
    }, [updateStopIsSuccess, updateStopError, updateStopIsError]);

    const menuOptions = () => [
        //     {
        //     id: 'move',
        //     title: 'Move',
        //     icon: <MoveUpOutlinedIcon fontSize="small" />,
        //     disabled: !(selectedItem?.status === TIFFIN.DELIVERY_ROUTES_STOP_STATUS.PENDING || selectedItem?.status === TIFFIN.DELIVERY_ROUTES_STOP_STATUS.ON_THE_WAY),
        //     action: () => {
        //         setShowMoveConfirmationModal(true);
        //     }
        // },
        // {
        //     id: 'edit',
        //     title: 'Edit',
        //     icon: <EditIconOutlined fontSize="small" />,
        //     disabled: !(selectedItem?.status === TIFFIN.DELIVERY_ROUTES_STOP_STATUS.PENDING || selectedItem?.status === TIFFIN.DELIVERY_ROUTES_STOP_STATUS.ON_THE_WAY),
        //     action: () => {
        //         navigate(selectedItem?.id);
        //     }
        // },
        // {
        //     id: 'active',
        //     title: 'Active',
        //     icon: <CheckCircleIcon fontSize="small" />,
        //     disabled: selectedItem?.status !== TIFFIN.DELIVERY_ROUTES_STOP_STATUS.CANCELLED,
        //     action: () => {
        //         setShowActiveConfirmationModal(true);
        //     }
        // },
        // {
        //     id: 'cancel',
        //     title: 'Cancel',
        //     icon: <CancelIcon fontSize="small" />,
        //     disabled: !(selectedItem?.status === TIFFIN.DELIVERY_ROUTES_STOP_STATUS.PENDING || selectedItem?.status === TIFFIN.DELIVERY_ROUTES_STOP_STATUS.ON_THE_WAY),
        //     action: () => {
        //         setShowCancelConfirmationModal(true);
        //     }
        // }
    ];

    const processedItems = processedStops?.map((item) => {
        const { id, position, name, mealPlan, address, imageName, noteForBusiness, noteForCustomer, status, deliveryTime } = item;
        return {
            stop: position + 1,
            name,
            mealPlan,
            address: address?.description,
            status: getStopStatusChip(status),
            deliveryTime,
            imageName: (imageName &&
                <IconButton
                    onClick={async () => {
                        const res = await useGetStopQueryTrigger(id);
                        if (res?.data?.data?.imageUrl) {
                            window.open(res?.data?.data?.imageUrl, '_blank');
                        } else {
                            errorSnackBar({ message: 'Image fetch failed' });
                        }
                    }}
                >
                    <Tooltip title="View Image">
                        <OpenInNewIcon />
                    </Tooltip>
                </IconButton>) || '--',
            noteForBusiness: (noteForBusiness && <Tooltip title={noteForBusiness} placement='top' arrow>
                <MessageIcon />
            </Tooltip>) || '--',
            noteForCustomer: (noteForCustomer &&
                <Tooltip title={noteForCustomer} placement='top' arrow>
                    <MessageIcon />
                </Tooltip>) || '--',
            // action: <div>
            //     <IconButton
            //         aria-label="more"
            //         id="route-stop-long-menu"
            //         aria-controls={open ? 'route-stop-long-menu' : undefined}
            //         aria-expanded={open ? 'true' : undefined}
            //         aria-haspopup="true"
            //         onClick={(event) => {
            //             setSelectedItem(item);
            //             handleClick(event);
            //         }}
            //     >
            //         <MoreVertIcon />
            //     </IconButton>
            //     <Menu
            //         id="route-stop-long-menu"
            //         MenuListProps={{
            //             'aria-labelledby': 'route-stop-long-menu',
            //         }}
            //         anchorEl={anchorEl}
            //         open={open}
            //         onClose={handleClose}
            //         PaperProps={{
            //             style: {
            //                 maxHeight: 48 * 4.5,
            //                 width: '20ch',
            //             },
            //         }}
            //     >
            //         {menuOptions().map(({ id, title, icon, action, disabled }) => (
            //             <MenuItem
            //                 disabled={disabled}
            //                 key={id}
            //                 onClick={(e) => {
            //                     action();
            //                     handleClose(e);
            //                 }}>
            //                 <ListItemIcon>
            //                     {icon}
            //                 </ListItemIcon>
            //                 {title}
            //             </MenuItem>
            //         ))}
            //     </Menu>
            // </div>,
            id
        }
    });

    const handleDownload = async () => {
        if (stops?.length) {
            setDownloadLoading(true);
            const doc = new jsPDF();
            let routeName = routeDetails?.data?.name;
            let routeDate = getFormattedDateFromUnixTimestamp(routeDetails?.data?.startTime);
            doc.setFontSize(16);
            doc.setTextColor(128, 128, 128);
            let text = `Stop List: ${routeDate}`;
            const x = 105;
            let y = 10;
            doc.text(text, x, y, { align: 'center' });
            let textWidth = doc.getTextWidth(text);
            let underlineY = y + 1;
            doc.setDrawColor(128, 128, 128);
            doc.setLineWidth(0.5);
            doc.line(x - textWidth / 2, underlineY, x + textWidth / 2, underlineY);

            doc.setFontSize(18);
            doc.setFont('helvetica', 'bold');
            y = 20;
            text = `Route name: ${routeName}`;
            doc.text(text, x, y, { align: 'center' });
            textWidth = doc.getTextWidth(text);
            underlineY = y + 1;
            doc.setDrawColor(128, 128, 128);
            doc.setLineWidth(0.5);
            doc.line(x - textWidth / 2, underlineY, x + textWidth / 2, underlineY);

            const tableData = [];
            stops.forEach((stop) => {
                tableData.push([
                    stop?.position + 1,
                    stop?.customer?.firstName + ' ' + stop?.customer?.lastName,
                    stop?.address?.description,
                    stop?.customer?.phoneNumber?.number,
                    stop?.customer?.deliveryInstructions,
                ]);
            });

            const headers = ['Stop', 'Name', 'Address', 'Contact Number', 'Drop Point'];

            autoTable(doc, {
                head: [headers],
                body: tableData,
                theme: 'grid',
                startY: 30,
                margin: { left: 10, right: 10, bottom: 15 },
            });

            doc.setFontSize(8);
            for (let i = 1; i <= doc.getNumberOfPages(); i++) {
                doc.setPage(i);
                var img = new Image();
                img.src = DinespotLogo;
                doc.addImage(img, 'PNG', 10, 285, 20, 5);
                doc.text(185, 288, `Page ${i} / ${doc.getNumberOfPages()}`);
            }

            routeName = routeName.replace(/\s+/g, '_');
            routeDate = routeDate.replace(/\s+/g, '_');
            doc.save(`route_${routeName}_stop_list_${routeDate}.pdf`);
            setDownloadLoading(false);
        }
    };

    const cancelConfirmationModal = <ConfirmationModal
        open={showCancelConfirmationModal}
        handleClose={() => {
            setShowCancelConfirmationModal(false)
        }}
        title={<Grid container spacing={2}>
            <Grid size={12}>
                <Typography variant='h6'>{`Are you sure want to cancel stop for ${selectedItem?.name}`}</Typography>
            </Grid>
        </Grid>}
        type="Update"
        actionHandler={() => {
            const stopToUpdate = stops?.find((row) => row.id === selectedItem?.id);
            let copiedItem = JSON.parse(JSON.stringify(stopToUpdate));
            copiedItem.status = TIFFIN.DELIVERY_ROUTES_STOP_STATUS.CANCELLED;
            updateStop(copiedItem);
            setShowCancelConfirmationModal(false);
        }}
    />;

    const activeConfirmationModal = <ConfirmationModal
        open={showActiveConfirmationModal}
        handleClose={() => {
            setShowActiveConfirmationModal(false)
        }}
        title={<Grid container spacing={2}>
            <Grid size={12}>
                <Typography variant='h6'>{`Are you sure want to active back stop for ${selectedItem?.name}`}</Typography>
            </Grid>
        </Grid>}
        type="Update"
        actionHandler={() => {
            const stopToUpdate = stops?.find((row) => row.id === selectedItem?.id);
            let copiedItem = JSON.parse(JSON.stringify(stopToUpdate));
            copiedItem.status = TIFFIN.DELIVERY_ROUTES_STOP_STATUS.PENDING;
            updateStop(copiedItem);
            setShowActiveConfirmationModal(false);
        }}
    />;

    // const moveConfirmationModal = <ConfirmationModal
    //     open={showMoveConfirmationModal}
    //     handleClose={() => {
    //         setShowMoveConfirmationModal(false)
    //     }}
    //     title={<Grid container spacing={2}>
    //         <Grid size={12}>
    //             <Typography variant='h6'>{`Select new address for this stop`}</Typography>
    //         </Grid>
    //         <Grid size={12}>
    //             <BaseTextField />
    //         </Grid>
    //     </Grid>}
    //     type="Update"
    //     actionHandler={() => {
    //         const item = stops?.find((row) => row.id === selectedItem?.id);
    //         const copiedItem = { ...item };
    //         copiedItem.address = newAddress;
    //         delete copiedItem.status;
    //         updateStop(copiedItem);
    //         setNewAddressInputValue('');
    //         setNewAddress(null);
    //         setSelectedItem(null);
    //         setShowMoveConfirmationModal(false);
    //     }}
    // />;

    if (fetchLoading || isLoading || updateStopIsLoading) return <TuiSpinner />;

    const menuItems = <Grid size={12} container spacing={1} sx={{ mt: 0.5 }}>
        {stops?.length > 0 && <>
            <Grid size='auto'>
                <CoreButton
                    fullWidth={false}
                    variant="outlined"
                    aria-label="download-pdf"
                    toolTipTitle="Download stops data in pdf"
                    startIcon={<DownloadForOfflineOutlinedIcon />}
                    onClickHandler={handleDownload}
                    isLoading={downloadLoading}
                    >
                    Download
                </CoreButton>
            </Grid>
            <Grid size='auto'>
                <CoreButton
                    fullWidth={false}
                    variant="contained"
                    aria-label="map-view"
                    startIcon={<PublicOutlinedIcon />}
                    toolTipTitle="View stops in Google Map"
                    onClickHandler={() => { setShowMapDrawer(true) }}>
                    Map View
                </CoreButton>
            </Grid>
        </>}
    </Grid>;

    return (
        <>
            <Grid container spacing={1}>
                <Grid size={12}>
                    <TuiAppBar
                        // buttonClickHandler={() => {
                        //     navigate('new');
                        // }}
                        // buttonDisabled={routeDetails?.data?.status === TIFFIN.DELIVERY_ROUTES_STATUS.CANCELLED || routeDetails?.data?.status === TIFFIN.DELIVERY_ROUTES_STATUS.COMPLETED}
                        menuItems={menuItems}
                        title={<Box display="flex" alignItems="center">
                            <Typography variant="h6" fontWeight="bold" sx={{ pl: 1 }}>
                                {`${routeDetails?.data?.name || 'Route'} Stops`}
                            </Typography>
                            <IconButton
                                onClick={() => fetchAllStops()}
                                aria-label="refresh"
                                size="medium">
                                <RefreshIcon fontSize="inherit" style={{ color: '#FF4444' }} />
                            </IconButton>
                        </Box>}
                    />
                </Grid>
                <Grid size={12} container sx={{pb: 15}}>
                    {processedItems?.length > 0 && <Table headers={headCells} data={processedItems} isLoading={isLoading} selectedRow={() => { }} />}
                </Grid>
            </Grid>
            <Grid container size={12}>
                {!stops?.length && <DataNotFound title="stops" />}
            </Grid>
            <Footer>
                <Grid
                    container
                    justifyContent='flex-end'
                    size={12}
                    spacing={2}
                    sx={{ p: 1 }}
                >
                    <CoreButton
                        startIcon={<ArrowBackIosNewOutlinedIcon />}
                        fullWidth={false}
                        variant='outlined'
                        onClick={() => navigate(-1)}
                    >
                        Back
                    </CoreButton>
                </Grid >
            </Footer>
            <MapViewDrawer stops={stops} item={routeDetails?.data} open={showMapDrawer} handleClose={() => setShowMapDrawer(false)} />
            {cancelConfirmationModal}
            {activeConfirmationModal}
        </>
    );
}
