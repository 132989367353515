import React, { useState } from 'react';
import { CircularProgress, Grid, IconButton, Typography } from '@mui/material';
import moment from 'moment';
import DownloadIcon from '@mui/icons-material/Download';
import DinespotLogo from '../../../assets/images/dinespot_black_logo.png';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';

import { tiffinsApi } from '../../../js/slices/api_slices';
import { TuiAppBar, Table, DatePicker, DataNotFound, TuiSpinner, Hooks } from '../../../core';
import { getFormattedDateFromUnixTimestamp } from '../../../js/lib/utils';

const { useGetTiffinCookingPlanQuery } = tiffinsApi;
const { useSnackBarNotification } = Hooks;

const headCells = [{
  id: 'name',
  title: 'Name',
}, {
  id: 'quantity',
  title: 'Quantity',
}, {
  id: 'unit',
  title: 'Unit',
}];

const tiffinHeadCells = [{
  id: 'name',
  title: 'Name',
}, {
  id: 'quantity',
  title: 'Quantity',
}];

export default function TiffinsCookingPlan() {
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(moment());
  const { successSnackBar } = useSnackBarNotification();
  const {
    data,
    isLoading,
  } = useGetTiffinCookingPlanQuery({
    date: moment(selectedDate).endOf('day').valueOf(),
  }, { refetchOnMountOrArgChange: true });

  const processedItems = data?.data?.items?.map((item) => {
    const { name, quantity, unit } = item;
    return {
      name,
      quantity,
      unit,
      id: name,
    };
  });

  const processedTiffins = data?.data?.tiffins?.map((item) => {
    const { name, quantity } = item;
    return {
      name,
      quantity,
      id: name,
    };
  });

  const totalTiffins = processedTiffins?.reduce((acc, item) => acc + item.quantity, 0);
  processedTiffins?.push({ name: 'Total', quantity: totalTiffins });

  const handleCookingPlanDownload = () => {
    setLoading(true);
    if (processedItems?.length || processedTiffins?.length) {
      const doc = new jsPDF();
      doc.setFontSize(16);
      doc.setTextColor(128, 128, 128);
      const text = `Cooking Plan: ${getFormattedDateFromUnixTimestamp(selectedDate)}`;
      const x = 105;
      const y = 10;
      doc.text(text, x, y, { align: 'center' });
      const textWidth = doc.getTextWidth(text);
      const underlineY = y + 1;
      doc.setDrawColor(128, 128, 128);
      doc.setLineWidth(0.5);
      doc.line(x - textWidth / 2, underlineY, x + textWidth / 2, underlineY);

      autoTable(doc, {
        theme: 'grid',
        columnStyles: { 0: { cellWidth: 50 }, 1: { cellWidth: 20 }, 2: { cellWidth: 30 } },
        head: [headCells.map(({ title }) => title)],
        body: processedItems?.length ? Object.values(processedItems).map(({ name, quantity, unit }) => [name, quantity, unit]) : [],
        startY: 25,
        margin: { left: 10, bottom: 15 },
      });

      autoTable(doc, {
        theme: 'grid',
        columnStyles: { 0: { cellWidth: 50 }, 1: { cellWidth: 20 } },
        head: [tiffinHeadCells.map(({ title }) => title)],
        body: processedTiffins?.length ? Object.values(processedTiffins).map(({ name, quantity }) => [name, quantity]) : [],
        startY: 25,
        margin: { left: 120, bottom: 15 },
      });

      doc.setFontSize(8);
      for (let i = 1; i <= doc.getNumberOfPages(); i++) {
        doc.setPage(i);
        var img = new Image();
        img.src = DinespotLogo;
        doc.addImage(img, 'PNG', 10, 285, 20, 5);
        doc.text(185, 288, `Page ${i} / ${doc.getNumberOfPages()}`);
      }

      const today = getFormattedDateFromUnixTimestamp(selectedDate).replace(/\s+/g, '_');
      doc.save(`coooking_plan_${today}.pdf`);
    } else {
      successSnackBar({ message: 'No data available.' });
    }
    setLoading(false);
  };

  const CookingPlanContent = ({ processedItems, processedTiffins, isLoading }) => (
    <Grid container spacing={3}>
      <Grid item xs={12} container>
        <Grid item xs={6}>
          <Typography variant='h5'>Items</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant='h5'>Meals</Typography>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Table headers={headCells} data={processedItems} isLoading={isLoading} sort={true} />
      </Grid>
      <Grid item xs={6}>
        <Table headers={tiffinHeadCells} data={processedTiffins} isLoading={isLoading} sort={true} />
      </Grid>
    </Grid>
  );

  return (
    <Grid container>
      <Grid item container xs={12}>
        <Grid item xs={3}>
          <TuiAppBar
            title="Cooking Plan"
          />
        </Grid>
        <Grid item xs={9} sx={{ mt: 1 }} container justifyContent='flex-end' spacing={1}>
          <Grid item xs='auto'>
            <DatePicker
              onChange={(event) => {
                setSelectedDate(moment(event));
              }}
              value={selectedDate}
              label="Date"
            />
          </Grid>
          <Grid item xs="auto">
            <IconButton
              disabled={!data?.data?.items?.length || loading}
              onClick={handleCookingPlanDownload}
            >
              {loading ? <CircularProgress size={24} /> : <DownloadIcon />}
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      {isLoading && <TuiSpinner />}
      {!isLoading && <> <Grid item xs={12} container direction='column' justifyContent='center' alignItems='center' sx={{ mt: 10 }}>
        {processedItems?.length > 0 && (
          <div>
            <div style={{ display: 'none' }}>
              <Grid container justifyContent="center">
                <Typography variant='h5' style={{ marginBottom: '16px', marginTop: '16px', textDecoration: 'underline' }}>
                  Cooking Plan: {getFormattedDateFromUnixTimestamp(selectedDate)}
                </Typography>
              </Grid>
              <CookingPlanContent
                processedItems={processedItems}
                processedTiffins={processedTiffins}
                isLoading={isLoading}
              />
            </div>
            <div>
              <CookingPlanContent
                processedItems={processedItems}
                processedTiffins={processedTiffins}
                isLoading={isLoading}
              />
            </div>
          </div>
        )}
      </Grid>
        <Grid item xs={12}>
          {processedItems?.length === 0 && <DataNotFound title="cooking plan" />}
        </Grid></>}
    </Grid >
  );
}
