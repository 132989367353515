import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Modal,
  Typography,
  Grid,
  Chip,
  Divider,
} from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import { CoreButton, ToggleDays, TuiSpinner } from '../../../core';
import { capitalizeFirstLetter, getFormattedDateFromUnixTimestamp, getFormattedDateTimeFromUnixTimestamp } from '../../../js/lib/utils';
import { tiffinsApi } from '../../../js/slices/api_slices';
import { DELIVERY_METHOD, TIFFIN } from '../../../js/lib/constants';
import { getStatusChip, getRemainingDays, getStatusLabel } from '../customer_utils';

const { useGetTiffinSubscriberWithRouteAndDriverQuery } = tiffinsApi;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  p: 2,
  minHeight: 300,
};

export default function CustomerViewModal({ open, handleClose, item }) {
  const navigate = useNavigate();
  const settings = useSelector((store) => store?.settings?.settings);
  let { data: subscriberData, isLoading: subscriberDataLoading } = useGetTiffinSubscriberWithRouteAndDriverQuery({
    id: item?.id,
  }, { refetchOnMountOrArgChange: true, skip: !(item?.id && open) });

  subscriberData = subscriberData?.data;

  const remainingDays = getRemainingDays(subscriberData, settings?.timezone);

  return (
    <Modal
      open={open}
      onClose={() => {
        handleClose();
      }}
    >
      <Grid
        container
        spacing={1}
        sx={style}>
        {subscriberDataLoading && <TuiSpinner />}
        {!subscriberDataLoading && <><Grid item xs={12} container>
          <Grid item xs={6} container spacing={1}>
            <Grid container direction="row" alignItems="center" item xs="auto" spacing={1}>
              <Grid item>
                <Typography sx={{ color: 'secondary' }} fontWeight='bold' fontSize={19}>
                  {subscriberData?.customer?.firstName} {subscriberData?.customer?.lastName}
                </Typography>
              </Grid>
              <Grid item>
                {getStatusChip(subscriberData)}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} container justifyContent='flex-end'>
            <Grid item xs="auto">
              <CoreButton
                startIcon={<EditOutlinedIcon />}
                variant='contained'
                onClickHandler={() => {
                  navigate('/customers/' + subscriberData?.id);
                }}
              >
                Edit
              </CoreButton>
            </Grid>
          </Grid>
        </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} container spacing={3}>
            <Grid item xs={12} container>
              <Grid xs={6} item>
                <Grid item xs={12}>
                  <Typography sx={{ color: 'secondary' }} fontWeight='bold'>Tiffin</Typography>
                </Grid>
                <Grid item xs={12} container>
                  <Grid item xs={8}>
                    <Typography sx={{ color: 'secondary' }}>{capitalizeFirstLetter(subscriberData?.tiffin?.name)}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid xs={6} item>
                <Grid item xs={12}>
                  <Typography sx={{ color: 'secondary' }} fontWeight='bold'>Price</Typography>
                </Grid>
                <Grid item xs={12} container>
                  <Grid item xs={8}>
                    <Typography sx={{ color: 'secondary' }}>$ {subscriberData?.tiffin?.plan?.price}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} container spacing={1}>
              <Grid item xs={6} container>
                <Grid item xs={12}>
                  <Typography sx={{ color: 'secondary' }} fontWeight='bold'>Start Date</Typography>
                </Grid>
                <Grid item xs={12} container>
                  <Typography sx={{ color: 'secondary' }}>{getFormattedDateFromUnixTimestamp(subscriberData?.startDate)}</Typography>
                  {subscriberData?.status === TIFFIN.STATUS.NOT_STARTED && <Chip
                    sx={{ ml: 1 }}
                    size="small"
                    color={subscriberData?.startDate > Date.now() ? 'info' :
                      remainingDays > 6 ?
                        'success' :
                        remainingDays <= 5 && remainingDays >= 0 ? 'warning' : 'error'}
                    label={getStatusLabel(item, settings?.timezone)} />}
                </Grid>
              </Grid>
              <Grid item xs={6} container>
                <Grid item xs={12}>
                  <Typography sx={{ color: 'secondary' }} fontWeight='bold'>End Date</Typography>
                </Grid>
                <Grid item xs={12} container>
                  <Typography sx={{ color: 'secondary' }}>{getFormattedDateFromUnixTimestamp(subscriberData?.endDate)}</Typography>
                  {subscriberData?.status !== TIFFIN.STATUS.NOT_STARTED && <Chip
                    sx={{ ml: 1 }}
                    size="small"
                    color={subscriberData?.startDate > Date.now() ? 'info' :
                      remainingDays > 6 ?
                        'success' :
                        remainingDays <= 5 && remainingDays >= 0 ? 'warning' : 'error'}
                    label={getStatusLabel(subscriberData, settings?.timezone)} />}
                </Grid>
              </Grid>
            </Grid>
            {subscriberData?.holdStartDate &&
              subscriberData?.holdEndDate &&
              <Grid item xs={12} container spacing={1}>
                <Grid item xs={6}>
                  <Typography sx={{ color: 'secondary' }} fontWeight='bold'>Phone Number</Typography>
                </Grid>
                <Grid item container xs={6} >
                  <Typography sx={{ color: 'secondary' }} fontWeight='bold'>Hold Period</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ color: 'secondary' }}>{subscriberData?.customer?.phoneNumber?.number}</Typography>
                </Grid>
                <Grid item xs={6} container>
                  <Typography sx={{ color: 'secondary' }}>{`${getFormattedDateFromUnixTimestamp(subscriberData?.holdStartDate)} - ${getFormattedDateFromUnixTimestamp(subscriberData?.holdEndDate)}`}</Typography>
                </Grid>
              </Grid>}
            <Grid item xs={12} container>
              <Grid item xs={2}>
                <Grid item xs={12} container>
                  <Typography sx={{ color: 'secondary' }} fontWeight='bold'>Unit</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={{ color: 'secondary' }}>{subscriberData?.customer?.unit || '--'} </Typography>
                </Grid>
              </Grid>
              <Grid item xs={10} container>
                <Grid item xs={12} container>
                  <Typography sx={{ color: 'secondary' }} fontWeight='bold'>Address</Typography>
                  <Chip size="small" label={subscriberData?.shipping} sx={{ ml: 1 }} />
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={{ color: 'secondary' }}>{subscriberData?.customer?.address?.description} </Typography>
                </Grid>
              </Grid>
            </Grid>
            {subscriberData?.shipping === DELIVERY_METHOD.HOME_DELIVERY && <Grid item xs={12} container direction='row'>
              <Grid item xs={12} container>
                <Grid item xs={6}>
                  <Typography sx={{ color: 'secondary' }} fontWeight='bold'>Route</Typography>
                </Grid>
                <Grid item container xs={6} >
                  <Typography sx={{ color: 'secondary' }} fontWeight='bold'>Driver</Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} container>
                <Grid item xs={6}>
                  <Typography sx={{ color: 'secondary' }}>{subscriberData?.route?.name || ''}</Typography>
                </Grid>
                <Grid item container xs={6}>
                  <Typography sx={{ color: 'secondary' }}>{subscriberData?.route?.driver?.firstName || ''} {subscriberData?.route?.driver?.lastName || ''}</Typography>
                </Grid>
              </Grid>
            </Grid>}
            <Grid item xs={12} container>
              <Grid item xs={6} container>
                <Grid item xs={12} container>
                  <Typography sx={{ color: 'secondary' }} fontWeight='bold'>Delivery Instructions</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={{ color: 'secondary' }}>{subscriberData?.deliveryInstructions || '--'} </Typography>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid item xs={12} container>
                  <Typography sx={{ color: 'secondary' }} fontWeight='bold'>Comments</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={{ color: 'secondary' }}>{subscriberData?.comment || '--'} </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} container spacing={1}>
              <Grid item xs={12}>
                <Typography sx={{ color: 'secondary' }} fontWeight='bold'>{subscriberData?.shipping} Days</Typography>
              </Grid>
              <Grid item xs={12} container>
                <Grid item xs={12}>
                  <ToggleDays existingDays={subscriberData?.deliveryDays} disabled={true} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} container>
              <Grid item xs={6}>
                <Typography sx={{ color: 'grey.500', fontSize: '0.75rem' }}>
                  Created at: {subscriberData?.createdAt ? getFormattedDateTimeFromUnixTimestamp(subscriberData?.createdAt) : '--'}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ color: 'grey.500', fontSize: '0.75rem' }}>
                  Last modified at: {subscriberData?.modifiedAt ? getFormattedDateTimeFromUnixTimestamp(subscriberData?.modifiedAt) : '--'}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </>
        }
      </Grid>
    </Modal >
  );
}
