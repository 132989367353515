import React from 'react';
import {
  Drawer,
  Grid2 as Grid,
  Typography,
  Box,
  IconButton,
  Tooltip,
} from '@mui/material';
import ArrowBackOutlined from '@mui/icons-material/ArrowBackOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import MessageIcon from '@mui/icons-material/Message';

import { Table, Hooks } from '../../../core';
import { getStopStatusChip } from '../routes_utils';
import { stopsApi } from '../../../js/slices/api_slices';
import { getFormattedTimeFromUnixTimestamp } from '../../../js/lib/utils';

const headCells = [{
  id: 'stop',
  title: 'Stop',
}, {
  id: 'name',
  title: 'Name',
}, {
  id: 'mealPlan',
  title: 'Meal Plan',
},{
  id: 'phoneNumber',
  title: 'Phone Number',
},{
  id: 'address',
  title: 'Address',
}, {
  id: 'status',
  title: 'Status',
}, {
  id: 'deliveryTime',
  title: 'Delivery Time',
}, {
  id: 'image',
  title: 'Image',
}, {
  id: 'businessNote',
  title: 'Business Note',
}, {
  id: 'customerNote',
  title: 'Customer Note',
}];

const { useLazyGetStopQuery } = stopsApi;
const {useSnackBarNotification} = Hooks;

export default function RouteHistoryStopsDrawer({ items, open, handleClose }) {
  const [useGetStopQueryTrigger, { isLoading }] = useLazyGetStopQuery();
  const { errorSnackBar } = useSnackBarNotification();

  const processedItems = items?.map((item) => {
    const { position,customer, id, address, imageName, noteForBusiness, noteForCustomer, status, events } = item;
    const { firstName, lastName, phoneNumber, tiffin } = customer;
    return {
      position: position + 1,
      name: `${firstName} ${lastName}`,
      mealPlan: tiffin?.name,
      phoneNumber: phoneNumber?.number,
      address: address?.description,
      status: getStopStatusChip(status),
      deliveryTime: events?.finish ? getFormattedTimeFromUnixTimestamp(events?.finish) : '--',
      imageName: (imageName &&
          <IconButton
              onClick={async () => {
                  const res = await useGetStopQueryTrigger(id);
                  if (res?.data?.data?.imageUrl) {
                      window.open(res?.data?.data?.imageUrl, '_blank');
                  } else {
                      errorSnackBar({ message: 'Image fetch failed' });
                  }
              }}
          >
              <Tooltip title="View Image">
                  <OpenInNewIcon />
              </Tooltip>
          </IconButton>) || '--',
      noteForBusiness: (noteForBusiness && <Tooltip title={noteForBusiness} placement='top' arrow>
          <MessageIcon />
      </Tooltip>) || '--',
      noteForCustomer: (noteForCustomer &&
          <Tooltip title={noteForCustomer} placement='top' arrow>
              <MessageIcon />
          </Tooltip>) || '--',
      id,
    };
  });

  return (
    <Drawer
      anchor="right"
      PaperProps={{
        sx: { width: '70%' },
      }}
      open={open}
      onClose={handleClose}>
      <Grid container sx={{ p: 2 }} spacing={1}>
        <Grid size={12} container>
          <Grid size='auto'>
            <IconButton
              onClick={handleClose}
              sx={{ p: 0, pr: 1, pt: 0.5 }}
            >
              <ArrowBackOutlined />
            </IconButton>
          </Grid>
          <Grid size='auto'>
            <Typography fontWeight="bold" variant="h6">
              Stops
            </Typography>
          </Grid>
        </Grid>
        <Grid size={12} container>
          <Grid size={12}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="stretch"
              padding={1}
              sx={{
                height: '90vh',
                width: '100%',
                '& .actions': {
                  color: 'text.secondary',
                },
                '& .textPrimary': {
                  color: 'text.primary',
                },
              }}
            >
              <Table
                headers={headCells}
                data={processedItems}
                setSelectedRow={() => { }}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Drawer>
  );
}