import React from 'react';

import DirectionsIcon from '@mui/icons-material/DirectionsOutlined';
import SettingsIcon from '@mui/icons-material/SettingsOutlined';
import PeopleIcon from '@mui/icons-material/PeopleOutlined';
import EngineeringIcon from '@mui/icons-material/EngineeringOutlined';
import DescriptionIcon from '@mui/icons-material/DescriptionOutlined';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import ExposureIcon from '@mui/icons-material/ExposureOutlined';
import LeaderboardIcon from '@mui/icons-material/LeaderboardOutlined';
import AnalyticsIcon from '@mui/icons-material/AnalyticsOutlined';
import LanguageIcon from '@mui/icons-material/LanguageOutlined';
import TakeoutDiningIcon from '@mui/icons-material/TakeoutDiningOutlined';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import LocalMallIcon from '@mui/icons-material/LocalMallOutlined';
import OutdoorGrillIcon from '@mui/icons-material/OutdoorGrillOutlined';

export const drawerNavLocations = [
  {
    name: 'Meal Plans',
    path: '/meal-plans',
    icon: <TakeoutDiningIcon />,
    rolesAvailability: 'mealPlans',
  }, {
    name: 'Customers',
    path: '/customers',
    icon: <PeopleIcon />,
    rolesAvailability: 'customers',
  }, {
    name: 'Cooking Plan',
    path: '/cooking-plan',
    icon: <OutdoorGrillIcon />,
    rolesAvailability: 'cookingPlan',
  },
  {
    name: 'Packing',
    path: '/packing',
    icon: <LocalMallIcon />,
    rolesAvailability: 'packing',
  },
  {
    name: 'Employees',
    path: '/employees',
    icon: <EngineeringIcon />,
    rolesAvailability: 'employees',
  }, {
    name: 'Routes',
    path: '/routes',
    icon: <DirectionsIcon />,
    rolesAvailability: 'routes',
  }, {
    path: '/expenses',
    icon: <ExposureIcon />,
    name: 'Expenses',
    rolesAvailability: 'expenses',
  }, {
    path: '/leads',
    icon: <LeaderboardIcon />,
    name: 'Leads',
    rolesAvailability: 'leads',
  }, {
    path: '/reports',
    icon: <AnalyticsIcon />,
    name: 'Reports',
    rolesAvailability: 'reports',
  },
  {
    path: '/settings',
    icon: <SettingsIcon />,
    name: 'Settings',
    rolesAvailability: 'settings',
    routes: [{
      name: 'General',
      path: 'general',
      icon: <ManageAccountsIcon />,
      rolesAvailability: 'general',
    },
    {
      name: 'Customer Portal',
      path: 'customer_portal',
      icon: <LanguageIcon />,
      rolesAvailability: 'customerPortal',
    },
    {
      name: 'Business',
      path: 'business',
      icon: <AdminPanelSettingsIcon />,
      rolesAvailability: 'business',
    }, {
      name: 'Billing',
      path: 'billing',
      icon: <DescriptionIcon />,
      rolesAvailability: 'billing',
    }],
  },
];
