import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  FormControlLabel,
  Modal,
  Grid,
  Typography,
  Box,
  RadioGroup,
  Radio
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { DatePicker, CoreButton, Hooks, BaseTextField } from '../../../core';
import { tiffinPaymentApi } from '../../../js/slices/api_slices';
import { TIFFIN } from '../../../js/lib/constants';

const { useSnackBarNotification } = Hooks;
const { useCreateTiffinPaymentMutation, useUpdatePaymentMutation } = tiffinPaymentApi;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 650,
  bgcolor: 'background.paper',
  border: '1px solid',
  borderRadius: '5px',
  p: 2,
};

export default function CustomerPaymentForm({ open, handleClose, viewOnly, item, fetchSubscriber, action, rows, setRows }) {
  const { errorSnackBar, successSnackBar } = useSnackBarNotification();
  const { handleSubmit, formState: { errors }, control, reset, register, watch } = useForm({
    defaultValues: {
      startDate: moment(item?.endDate).startOf('day').add(1, 'day').valueOf(),
      endDate: null,
      referenceId: null,
      amount: item?.tiffin?.plan?.price,
      status: TIFFIN.PAYMENTS.PAID,
      expectedPaymentDate: null,
    },
    mode: 'onChange',
  });

  useEffect(() => {
    const planType = item?.tiffin?.plan?.type;
    let planFrequency = 'month';
    if (planType === TIFFIN.PLANS.SINGLE) {
      planFrequency = 'day';
    } else if (planType === TIFFIN.PLANS.WEEKLY) {
      planFrequency = 'week';
    } else if (planType === TIFFIN.PLANS.MONTHLY || planType === TIFFIN.PLANS.CUSTOM) {
      planFrequency = 'month';
    }
    reset({
      startDate: moment(item?.endDate).startOf('day').add(1, 'day').valueOf(),
      endDate: moment(item?.endDate).startOf('day').add(Number(item?.tiffin?.plan?.count), planFrequency).valueOf(),
      referenceId: null,
      amount: item?.tiffin?.plan?.price,
      status: TIFFIN.PAYMENTS.PAID,
      expectedPaymentDate: null,
    });
  }, [item?.startDate, item?.endDate]);

  const [createTiffinPayment, {
    data: createTiffinPaymentData,
    isSuccess: createTiffinPaymentSuccess,
    error: createTiffinPaymentError,
    isError: createTiffinPaymentIsError,
    isLoading: createTiffinPaymentIsLoading,
  }] = useCreateTiffinPaymentMutation();

  const [updatePayment, {
    data: updatePaymentData,
    isSuccess: updatePaymentSuccess,
    error: updatePaymentError,
    isError: updatePaymentIsError,
    isLoading: updatePaymentIsLoading,
  }] = useUpdatePaymentMutation();

  useEffect(() => {
    if (createTiffinPaymentSuccess && createTiffinPaymentData) {
      successSnackBar({ message: 'Payment created successfully' });
      const paymentData = createTiffinPaymentData.data;
      const updatedRows = [...rows];
      updatedRows.unshift({
        ...paymentData,
        startDate: moment(paymentData?.startDate).format('DD MMM YYYY'),
        endDate: moment(paymentData?.endDate).format('DD MMM YYYY'),
        expectedPaymentDate: paymentData?.expectedPaymentDate ? moment(paymentData?.expectedPaymentDate).format('DD MMM YYYY') : '--',
      });
      setRows(updatedRows.map((item, index) => {
        return {
          ...item,
          index,
        };
      }));
      fetchSubscriber({ id: paymentData?.tiffinSubscriberId });
      reset({
        startDate: moment(paymentData?.endDate).startOf('day').add(1, 'day').valueOf(),
        endDate: null,
        referenceId: null,
        amount: null,
        status: TIFFIN.PAYMENTS.PAID,
        expectedPaymentDate: null,
      });
      handleClose();
    } if (createTiffinPaymentIsError && createTiffinPaymentError) {
      errorSnackBar({ message: createTiffinPaymentError?.data?.errorMessage });
    }
  }, [createTiffinPaymentSuccess, createTiffinPaymentIsError, createTiffinPaymentData, createTiffinPaymentError]);

  useEffect(() => {
    if (updatePaymentSuccess && updatePaymentData) {
      successSnackBar({ message: 'Payment updated successfully' });
      const paymentData = updatePaymentData.data;
      setRows(rows?.map((item) => {
        if (item.id === paymentData.id) {
          return {
            ...item,
            id: paymentData?.id,
            startDate: moment(paymentData?.startDate).format('DD MMM YYYY'),
            endDate: moment(paymentData?.endDate).format('DD MMM YYYY'),
            amount: paymentData?.amount,
            status: paymentData?.status,
            referenceId: paymentData?.referenceId,
            expectedPaymentDate: paymentData?.expectedPaymentDate ? moment(paymentData?.expectedPaymentDate).format('DD MMM YYYY') : '--',
          };
        }
        return item;
      }));
      fetchSubscriber({ id: paymentData?.tiffinSubscriberId });
      reset({
        startDate: moment(paymentData?.endDate).startOf('day').add(1, 'day').valueOf(),
        endDate: null,
        referenceId: null,
        amount: null,
        status: TIFFIN.PAYMENTS.PAID,
        expectedPaymentDate: null,
      });
      handleClose();
    } if (updatePaymentIsError && updatePaymentError) {
      errorSnackBar({ message: updatePaymentError?.data?.errorMessage });
    }
  }, [updatePaymentSuccess, updatePaymentIsError, updatePaymentData, updatePaymentError]);

  useEffect(() => {
    if (action === 'update') {
      reset({
        ...item,
        startDate: moment(item?.startDate).valueOf(),
        endDate: moment(item?.endDate).valueOf(),
        expectedPaymentDate: item?.expectedPaymentDate ? moment(item?.expectedPaymentDate).valueOf() : null,
      });
    }
  }, [action]);

  const checkAndCreatePayment = (userInput) => {
    const pendingPayment = rows.find((row) => row.status === TIFFIN.PAYMENTS.PENDING);
    if (pendingPayment) {
      errorSnackBar({ message: 'Pending payment exists. Please mark it paid or delete to create new one' });
    } else {
      createTiffinPayment({
        tiffinSubscriberId: item?.id,
        data: {
          ...userInput,
          startDate: moment(userInput?.startDate).startOf('day').valueOf(),
          endDate: moment(userInput?.endDate).endOf('day').valueOf(),
          expectedPaymentDate: userInput?.expectedPaymentDate ? moment(userInput?.expectedPaymentDate).endOf('day').valueOf() : null,
        }
      });
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
    >
      <Box sx={style}>
        <Grid container spacing={1}>
          <Grid item container xs={12}>
            <Typography variant="h6" sx={{ mt: 1, mb: 1, color: 'secondary.contrastText.main' }}>
              Add payment
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            container
            spacing={2}
            sx={{ mt: 1 }}
            component="form"
            onSubmit={handleSubmit((userInput) => {
              const expectedPaymentDate = (userInput?.status === TIFFIN.PAYMENTS.PENDING && userInput?.expectedPaymentDate) ? moment(userInput?.expectedPaymentDate).endOf('day').valueOf() : null;
              action === 'add' ? checkAndCreatePayment(userInput) : updatePayment({
                ...userInput,
                id: item?.id,
                startDate: moment(userInput?.startDate).startOf('day').valueOf(),
                endDate: moment(userInput?.endDate).endOf('day').valueOf(),
                expectedPaymentDate,
              });
            })}
          >
            <Grid item xs={12} container spacing={1}>
              <Grid item xs={6}>
                <Controller
                  control={control}
                  rules={{ required: 'Invalid Start Date' }}
                  name="startDate"
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      required={true}
                      control={control}
                      onChange={(event) => {
                        onChange(event);
                      }}
                      errors={errors}
                      value={value ? moment(value) : null}
                      label="Start Date"
                      disabled={item?.status !== TIFFIN.STATUS.EXPIRED}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  control={control}
                  rules={{
                    required: 'Invalid End Date',
                    validate: value => {
                      if (moment(value).isBefore(moment(watch('startDate')))) {
                        return 'End Date cannot be before Start Date';
                      }
                    }
                  }}
                  name="endDate"
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      name="endDate"
                      required={true}
                      control={control}
                      onChange={(event) => {
                        onChange(event);
                      }}
                      errors={errors}
                      value={value ? moment(value) : null}
                      label="End Date"
                      minDate={moment(watch('startDate'))}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} container spacing={1}>
              <Grid item xs={6}>
                <BaseTextField
                  id='amount'
                  name="amount"
                  label="Amount"
                  disabled={viewOnly}
                  errors={errors}
                  type='number'
                  validate={register('amount', {
                    required: 'Amount is required',
                  },
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <BaseTextField
                  id='referenceId'
                  name="referenceId"
                  label="Reference Id"
                  disabled={viewOnly}
                  errors={errors}
                  required={false}
                  validate={register('referenceId')}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} container spacing={1}>
              <Grid item xs={6}>
                <Controller
                  control={control}
                  name="status"
                  render={({ field }) => {
                    return (
                      <Grid container direction="row" alignItems="center">
                        <Typography variant="body1" sx={{ marginRight: 2 }}>
                          Status
                        </Typography>
                        <RadioGroup
                          {...field}
                          onChange={(event, value) => field.onChange(value)}
                          value={field.value || item?.tiffin?.plan?.type}
                          row
                          name="status"
                        >
                          {Object.entries(TIFFIN.PAYMENTS).map(([key, value]) => {
                            return (
                              <FormControlLabel
                                key={key}
                                value={value}
                                control={
                                  <Radio
                                    sx={{
                                      '&, &.Mui-checked': {
                                        color: 'secondary.contrastText',
                                      },
                                    }}
                                  />
                                }
                                label={value}
                              />
                            );
                          })}
                        </RadioGroup>
                      </Grid>
                    );
                  }}
                />
              </Grid>
              {watch('status') === TIFFIN.PAYMENTS.PENDING &&
                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name="expectedPaymentDate"
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        name="expectedPaymentDate"
                        required={false}
                        control={control}
                        onChange={(event) => {
                          onChange(event);
                        }}
                        errors={errors}
                        value={value ? moment(value) : null}
                        label="Expected Payment Date"
                        minDate={moment(watch('startDate'))}
                      />
                    )}
                  />
                </Grid>}
            </Grid>
            <Grid item xs={12} justifyContent='flex-end' container spacing={1}>
              <Grid item xs="auto">
                <CoreButton
                  variant='contained'
                  fullWidth={false}
                  isLoading={createTiffinPaymentIsLoading}
                  onClickHandler={handleClose}
                >Close
                </CoreButton>
              </Grid>
              <Grid item xs="auto">
                <CoreButton
                  variant='contained'
                  fullWidth={false}
                  isLoading={createTiffinPaymentIsLoading || updatePaymentIsLoading}
                  disabled={!watch('endDate') || !watch('amount')}
                  type="submit"
                >{action}
                </CoreButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal >
  );
}

CustomerPaymentForm.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  viewOnly: PropTypes.bool,
  item: PropTypes.object,
  fetchSubscriber: PropTypes.func,
};