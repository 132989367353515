import React, { useEffect, useState } from 'react';
import ExcelJS from 'exceljs';
import moment from 'moment';
import { saveAs } from 'file-saver';
import { Grid2 as Grid, Button, Box, FormControlLabel, Paper, Stepper, Step, StepLabel, StepContent, Typography, Modal, Checkbox } from '@mui/material';
import { BaseDropzone, CoreButton } from '../../../core';
import { tiffinsApi, deliveryRoutesApi, dataImportApi } from '../../../js/slices/api_slices';

const { useLazyGetTiffinsQuery } = tiffinsApi;
const { useLazyGetDeliveryRoutesQuery } = deliveryRoutesApi;
const { useGetUploadUrlQuery, useStartProcessingMutation } = dataImportApi;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 420,
  color: 'black',
  backgroundColor: 'white',
  boxShadow: 24,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
  p: 2,
  borderRadius: '5px',
};

export default function DataImportWizard({ open, handleClose }) {
  const [activeStep, setActiveStep] = useState(0);
  const [uploadUrl, setUploadUrl] = useState('');
  const [file, setFile] = useState(null);
  const [clearExistingEntries, setClearExistingEntries] = useState(false);
  const [mealPlanFetchTrigger] = useLazyGetTiffinsQuery({});
  const [routesFetchTrigger] = useLazyGetDeliveryRoutesQuery({});

  const [buttonLoading, setButtonLoading] = useState(false);

  const { data } = useGetUploadUrlQuery({}, { refetchOnMountOrArgChange: true, skip: activeStep !== 4 });

  useEffect(() => {
    if (data?.data?.url) {
      setUploadUrl(data?.data?.url);
    }
  }, [data]);

  const [startProcessing, {
    data: startProcessingData,
    isSuccess: startProcessingSuccess,
    error: startProcessingError,
    isError: startProcessingIsError,
    isLoading: startProcessingIsLoading,
  }] = useStartProcessingMutation();

  const fetchAllMealPlans = async (page = 1, pageSize = 200) => {
    const response = await mealPlanFetchTrigger({ page, pageSize });

    let data = response?.data?.data;
    const totalCount = data.totalCount;
    const totalPages = Math.ceil(totalCount / pageSize);
    if (page < totalPages) {
      const nextPageData = await fetchAllMealPlans(page + 1, pageSize);
      data = { ...data, items: [...data.items, ...nextPageData.items] };
    }

    return data;
  };

  const fetchAllRoutes = async (page = 1, pageSize = 200) => {
    const response = await routesFetchTrigger({
      page,
      pageSize,
    });

    let data = response?.data?.data;
    const totalCount = data.totalCount;
    const totalPages = Math.ceil(totalCount / pageSize);
    if (page < totalPages) {
      const nextPageData = await fetchAllRoutes(page + 1, pageSize);
      data = { ...data, items: [...data.items, ...nextPageData.items] };
    }

    return data;
  };

  const handleExport = async () => {
    setButtonLoading(true);

    // Create a new workbook
    const workbook = new ExcelJS.Workbook();

    const mealPlanData = await fetchAllMealPlans();

    // Create Meal Plans sheet
    const mealPlansSheet = workbook.addWorksheet('Meal Plans');
    mealPlansSheet.columns = [
      { header: 'Name', key: 'name', width: 20 },
      { header: 'Description', key: 'description', width: 30 },
    ];
    mealPlanData?.items?.forEach((mealPlan) => {
      mealPlansSheet.addRow({
        name: mealPlan?.name,
        description: mealPlan?.description,
      });
    });

    // Apply grey color to the header row
    mealPlansSheet.getRow(1).eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'D3D3D3' }, // Light grey color
      };
    });

    const routesData = await fetchAllRoutes();
    const routesSheet = workbook.addWorksheet('Routes');
    routesSheet.columns = [
      { header: 'Name', key: 'name', width: 20 },
      { header: 'StartTime', key: 'startTime', width: 20 },
      { header: 'Driver', key: 'driver', width: 20 },
    ];
    routesData?.items?.forEach((route) => {
      routesSheet.addRow({
        name: route?.name,
        startTime: route?.startTime,
        driver: route?.driver?.firstName,
      });
    });

    // Apply grey color to the header row
    routesSheet.getRow(1).eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'D3D3D3' }, // Light grey color
      };
    });

    // Extract meal plan names for the "Meal Plan" column in the "Customers" sheet
    const mealPlanNames = mealPlanData?.items?.map((mealPlan) => mealPlan?.name);
    const routeNames = routesData?.items?.map((route) => route?.name);

    // Create Customers sheet with headers
    const customersSheet = workbook.addWorksheet('Customers');
    customersSheet.columns = [
      { header: 'FirstName', key: 'firstName', width: 20 },
      { header: 'LastName', key: 'lastName', width: 20 },
      { header: 'Unit (Optional)', key: 'unit', width: 20 },
      { header: 'Address', key: 'address', width: 30 },
      { header: 'PhoneNumber (Optional)', key: 'phoneNumber', width: 20 },
      { header: 'Email (Optional)', key: 'email', width: 30 },
      { header: 'Comment (Optional)', key: 'comment', width: 30 },
      { header: 'Meal Plan', key: 'mealPlan', width: 20 },
      { header: 'Plan Frequency', key: 'planFrequency', width: 20 },
      { header: 'Plan Frequency Count (Optional)', key: 'planFrequencyCount', width: 20 },
      { header: 'Start Date', key: 'startDate', width: 20 },
      { header: 'End Date (Optional)', key: 'endDate', width: 20 },
      { header: 'Drop Point (Optional)', key: 'dropPoint', width: 30 },
      { header: 'Shipping', key: 'shipping', width: 20 },
      { header: 'Route Name', key: 'routeName', width: 20 },
      { header: 'Sun', key: 'sun', width: 10 },
      { header: 'Mon', key: 'mon', width: 10 },
      { header: 'Tues', key: 'tues', width: 10 },
      { header: 'Wed', key: 'wed', width: 10 },
      { header: 'Thurs', key: 'thurs', width: 10 },
      { header: 'Fri', key: 'fri', width: 10 },
      { header: 'Sat', key: 'sat', width: 10 }
    ];

    // Merge the 7 columns for "Delivery Days" in the first row
    customersSheet.mergeCells('P1:V1');
    customersSheet.getCell('P1').value = 'Delivery Days';
    customersSheet.getCell('P1').alignment = { horizontal: 'center' };

    // Apply grey color to the header row
    customersSheet.getRow(1).eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'D3D3D3' }, // Light grey color
      };
    });

    // // Insert a new row with the days of the week
    customersSheet.insertRow(2, [
      '', '', '', '', 'Without +1', '', 'For Packing Team', '', '', 'Default 1', 'MM/DD/YYYY', 'MM/DD/YYYY. Applies only when Custom plan', 'For Drivers', '', '', 'Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat'
    ]);

    // Apply grey color to the new row
    customersSheet.getRow(2).eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'D3D3D3' }, // Light grey color
      };
    });

    const daysColumns = ['P', 'Q', 'R', 'S', 'T', 'U', 'V'];

    daysColumns.forEach((column) => {
      customersSheet.dataValidations.model[`${column}3:${column}9999`] = {
        type: 'list',
        allowBlank: false,
        formulae: [`"Yes,No"`],
        showErrorMessage: true,
        errorStyle: 'error',
        errorTitle: 'Invalid value',
        error: 'Please select "Yes" or "No".',
      };
    });

    customersSheet.dataValidations.model['K3:K9999'] = {
      type: 'date',
      operator: 'lessThan',
      showErrorMessage: true,
      formulae: [new Date(2040, 0, 1)],
      allowBlank: false,
      errorStyle: 'error',
      errorTitle: 'Invalid value',
      error: 'Please select a valid date.',
    };

    customersSheet.dataValidations.model['L3:L9999'] = {
      type: 'date',
      operator: 'lessThan',
      showErrorMessage: true,
      allowBlank: false,
      formulae: [new Date(2040, 0, 1)],
      errorStyle: 'error',
      errorTitle: 'Invalid value',
      error: 'Please select a valid date.',
    };

    customersSheet.dataValidations.model['I3:I9999'] = {
      type: 'list',
      allowBlank: false,
      formulae: [`"Monthly,Weekly, Custom"`],
      showErrorMessage: true,
      errorStyle: 'error',
      errorTitle: 'Invalid value',
      error: 'Please select a valid frequency from the list.',
    };

    customersSheet.dataValidations.model['H3:H9999'] = {
      type: 'list',
      allowBlank: false,
      formulae: [`"${mealPlanNames.join(',')}"`],
      showErrorMessage: true,
      errorStyle: 'error',
      errorTitle: 'Invalid value',
      error: 'Please select a valid meal plan from the list.',
    };

    customersSheet.dataValidations.model['N3:N9999'] = {
      type: 'list',
      allowBlank: false,
      formulae: [`"Home Delivery,Pick Up"`],
      showErrorMessage: true,
      errorStyle: 'error',
      errorTitle: 'Invalid value',
      error: 'Please select a valid shipping from the list.',
    };

    customersSheet.dataValidations.model['O3:O9999'] = {
      type: 'list',
      allowBlank: false,
      formulae: [`"${routeNames.join(',')}"`],
      showErrorMessage: true,
      errorStyle: 'error',
      errorTitle: 'Invalid value',
      error: 'Please select a valid route from the list.',
    };

    // Generate Excel file and trigger download
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/octet-stream' });
    saveAs(blob, 'dinespot_onboard_template.xlsx');

    setButtonLoading(false);
    return Promise.resolve();
  };

  const steps = [
    {
      label: 'Create meal plans',
      description: `Create a meal plan for each type of meal you offer.
              For example, you might offer "Veg", "Non Veg" etc.`,
    },
    {
      label: 'Create employees (Drivers mainly)',
      description:
        'Create employees who deliver the meals to your customers.',
    },
    {
      label: 'Create route for each driver',
      description: `Create a route for each driver. A route is a list of stops/addresses.`,
    },
    {
      label: 'Download template sheet',
      description: `Click on download button to download the excel template. This template have 4 sheets. 
                  Meal Plans, Drivers, Routes, and Customers. Fill in your customer data in the Customers sheet.`,
      component: <CoreButton
        variant='outlined'
        sx={{
          textDecoration: 'underline',
        }}
        fullWidth={false}
        onClickHandler={handleExport}
        isLoading={buttonLoading}
      >Download</CoreButton>

    },
    {
      label: 'Upload filled sheet',
      description: `Once you have filled in the customer data, upload the sheet here.`,
      component: <BaseDropzone onChange={setFile} />
    },
    {
      label: 'Start Processing',
      description: `Click on the button below to start processing the uploaded data.
                    Please wait while we process the data.`,
      component: <FormControlLabel
        control={
          <Checkbox
            checked={clearExistingEntries}
            onChange={(event) => setClearExistingEntries(event.target.checked)}
          />
        }
        label="Clear existing customers"
      />
    },
    {
      label: 'Result',
      description: `Below is the high level result. Download sheet for detailed result.`,
      component: <>
        <Typography>
          {`Processed successfully: ${startProcessingData?.data?.successCount || '-'}`}
        </Typography>
        <Typography>
          {`Failed to process: ${startProcessingData?.data?.failCount || '-'}`}
        </Typography>
        <CoreButton
          variant='contained'
          fullWidth={false}
          disabled={!startProcessingData?.data?.resultUrl}
          onClickHandler={() => window.open(startProcessingData?.data?.resultUrl)}
          isLoading={startProcessingIsLoading}
        >Download</CoreButton>
      </>
    },
  ];

  const handleNext = async () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);

    if (activeStep === 4) {
      if (!file) {
        return;
      }
      try {
        const response = await fetch(uploadUrl, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/octet-stream',
          },
          body: file,
        });

        if (response.ok) {
          console.log('File uploaded successfully');
        } else {
          console.error('File upload failed:', response.statusText);
        }
      } catch (error) {
        console.error('Error uploading file:', error);
      }

    }

    if (activeStep === 5) {
      startProcessing({ start: true, clearExistingEntries });
    };

    if (activeStep === 6) {
      setActiveStep(0);
      handleClose();
      return;
    }

  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="import-modal-title"
      aria-describedby="import-modal-description"
    >
      <Box sx={style}>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel
                optional={
                  index === steps.length - 1 ? (
                    <Typography variant="caption">Last step</Typography>
                  ) : null
                }
              >
                {step.label}
              </StepLabel>
              <StepContent>
                <Typography>{step.description}</Typography>
                {step?.component}
                <Box sx={{ mt: 2 }}>
                  <Grid container spacing={1}>
                    <Grid size='auto'>
                      <CoreButton
                        fullWidth={false}
                        variant="contained"
                        onClick={handleNext}
                      >
                        {index === steps.length - 1 ? 'Finish' : 'Continue'}
                      </CoreButton>
                    </Grid>
                    <Grid size='auto'>
                      <Button
                        fullWidth={false}
                        variant="outlined"
                        disabled={index === 0}
                        onClick={handleBack}
                      >
                        Back
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Box>
    </Modal>
  );
}