import React, { useEffect, useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { Grid, Tooltip, IconButton, MenuItem, Menu, ListItemIcon, Divider, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import HailIcon from '@mui/icons-material/Hail';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PeopleIconOutlined from '@mui/icons-material/PeopleOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import TocIconOutlined from '@mui/icons-material/TocOutlined';

import { Table, Hooks, ConfirmationModal } from '../../../core';
import TiffinSubscriberSelectionDrawer from './tiffins_subscribers_selection_drawer';
import TiffinGoodsDrawer from './tiffin_goods_drawer';
import { tiffinsApi } from '../../../js/slices/api_slices';
import { STORAGE_KEYS } from '../../../js/lib/constants';

const headCells = [{
  id: 'name',
  title: 'Name',
}, {
  id: 'single',
  title: 'Single',
}, {
  id: 'weekly',
  title: 'Weekly',
}, {
  id: 'monthly',
  title: 'Monthly',
}, {
  id: 'action',
  title: 'Actions',
}];

const { useDeleteTiffinMutation } = tiffinsApi;
const { useSnackBarNotification } = Hooks;

export default function TiffinListComponent({ isLoading, setPage, page, setRows, rows }) {
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { errorSnackBar, successSnackBar } = useSnackBarNotification();
  const [showSubscriberDrawer, setShowSubscriberDrawer] = useState(false);
  const [showItemsDrawer, setShowItemsDrawer] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [deleteTiffin, {
    data: deleteData,
    isSuccess: deleteSuccess,
    error: deleteError,
    isError: deleteIsError,
    isLoading: deleteIsLoading,
  }] = useDeleteTiffinMutation();

  useEffect(() => {
    if (deleteSuccess && deleteData) {
      successSnackBar({ message: 'Tiffin deleted successfully' });
      const newRows = rows.filter((item) => item.id !== selectedItem.id);
      if (!newRows.length && Number(page) > 1) {
        setPage(page - 1);
      }
      setRows(newRows);
    } if (deleteIsError && deleteError) {
      errorSnackBar({ message: deleteError?.data?.errorMessage });
    }
  }, [deleteData, deleteSuccess, deleteIsError, deleteError]);


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  const handleClose = (e) => {
    setAnchorEl(null);
    setSelectedItem(null);
    e.stopPropagation();
  };

  const menuOptions = (item) => [{
    id: `${item?.id}_duplicate`,
    title: 'Duplicate',
    icon: <ContentCopyOutlinedIcon fontSize="small" />,
    disabled: false,
    action: () => {
      const copiedItem = { ...selectedItem };
      delete copiedItem?.id;
      sessionStorage.setItem(STORAGE_KEYS.TIFFIN, JSON.stringify(copiedItem));
      navigate('/meal-plans/new');
    }
  },
  {
    id: `${item?.id}_delete`,
    title: 'Delete',
    icon: <DeleteOutlineOutlinedIcon fontSize="small" />,
    disabled: false,
    action: () => {
      setShowDeleteModal(true);
    }
  }, {
    id: `${item?.id}_customers`,
    title: 'Customers',
    icon: <PeopleIconOutlined fontSize="small" />,
    disabled: false,
    action: () => {
      setShowSubscriberDrawer(true);
    }
  }, {
    id: `${item?.id}_items`,
    title: 'Items',
    icon: <TocIconOutlined fontSize="small" />,
    disabled: false,
    action: () => {
      setShowItemsDrawer(true);
    }
  }];

  const priceComponent = (deliveryPrice, pickUpPrice) => {
    return (
      <Grid container spacing={1}>
        <Grid item>
          <Tooltip title="Delivery Price">
            <DeliveryDiningIcon sx={{ fontSize: 15, mr: 1, border: '1px solid black', borderRadius: '10%' }} />
          </Tooltip>
          {`$${deliveryPrice}`}
        </Grid>
        <Grid item>
          <Tooltip title="Pick Up Price">
            <HailIcon sx={{ fontSize: 15, mr: 1, border: '1px solid black', borderRadius: '10%' }} />
          </Tooltip>
          {`$${pickUpPrice}`}
        </Grid>
      </Grid>
    )
  };

  console.log('selectedItem', selectedItem);
  const processedItems = rows.map((item, index) => {
    const { id, name, price } = item;

    return {
      name,
      single: price?.delivery?.single !== price?.pickUp?.single ? priceComponent(price?.delivery?.single, price?.pickUp?.single) : `$${price?.delivery?.single}`,
      weekly: price?.delivery?.weekly !== price?.pickUp?.weekly ? priceComponent(price?.delivery?.weekly, price?.pickUp?.weekly) : `$${price?.delivery?.weekly}`,
      monthly: price?.delivery?.monthly !== price?.pickUp?.monthly ? priceComponent(price?.delivery?.monthly, price?.pickUp?.monthly) : `$${price?.delivery?.monthly}`,
      action: <div>
        <LoadingButton
          loading={selectedItem?.id === item?.id && deleteIsLoading}
          aria-label="more"
          id="long-button"
          aria-controls={open ? 'long-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={(event) => {
            setSelectedItem(item);
            handleClick(event);
            event.stopPropagation();
          }}
        >
          <MoreVertIcon />
        </LoadingButton>
        {selectedItem?.id === item?.id && <Menu
          id="long-menu"
          MenuListProps={{
            'aria-labelledby': 'long-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          {menuOptions(item).map(({ id, title, icon, action, disabled }) => [
            <MenuItem
              disabled={disabled}
              key={id}
              onClick={(e) => {
                action();
                handleClose(e);
              }}>
              <ListItemIcon>
                {icon}
              </ListItemIcon>
              {title}
            </MenuItem>,
            (title === "Delete") && <Divider key={`divider-${id}`} />
          ])}
        </Menu>}
      </div>,
      id,
    };
  });

  const deleteConfirmationModal = <ConfirmationModal
    open={showDeleteModal}
    handleClose={() => setShowDeleteModal(false)}
    title={<Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant='h6'>{'Are you sure want to delete this tiffin ?'}</Typography>
      </Grid>
    </Grid>}
    type={'Delete'}
    actionHandler={() => {
      deleteTiffin(selectedItem);
      setShowDeleteModal(false);
    }}
  />;

  return (
    <>
      <Grid container>
        <Table
          headers={headCells}
          data={processedItems}
          isLoading={isLoading}
          selectedRow={selectedItem}
          setSelectedRow={(row) => {
            navigate(row.id);
          }} />
      </Grid>
      {showSubscriberDrawer && <TiffinSubscriberSelectionDrawer
        item={selectedItem}
        open={showSubscriberDrawer}
        handleClose={() => {
          setSelectedItem(null);
          setShowSubscriberDrawer(false);
        }}
      />}
      {showItemsDrawer && <TiffinGoodsDrawer
        item={selectedItem}
        open={showItemsDrawer}
        handleClose={() => {
          setSelectedItem(null);
          setShowItemsDrawer(false);
        }}
      />}
      {showDeleteModal && deleteConfirmationModal}
    </>
  );
}
