import React, { useEffect } from 'react';
import moment from 'moment';
import { Grid2 as Grid, Alert, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';

import CustomerForm from '../components/customer_form';
import { tiffinsApi } from '../../../js/slices/api_slices';

import { TuiAppBar, Hooks, TuiSpinner, Footer, CoreButton } from '../../../core/index';

const { useUpdateTiffinSubscriberMutation, useGetTiffinSubscriberWithRouteAndDriverQuery } = tiffinsApi;

export default function EditCustomer() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { errorSnackBar, successSnackBar } = Hooks.useSnackBarNotification();
  const { data: fetchedData, isLoading: itemLoading } = useGetTiffinSubscriberWithRouteAndDriverQuery({
    id,
  }, { refetchOnMountOrArgChange: true, skip: !id });

  const { register, handleSubmit, formState: { errors, isDirty }, reset, control, setValue, watch, getValues } = useForm({
    defaultValues: fetchedData?.data,
  });

  const [updateTiffinSubscriber, {
    data,
    isSuccess,
    error,
    isError,
    isLoading,
  }] = useUpdateTiffinSubscriberMutation();

  useEffect(() => {
    reset(fetchedData?.data);
  }, [fetchedData]);

  useEffect(() => {
    if (isSuccess && data) {
      successSnackBar({ message: 'Customer data modified successfully' });
      reset(data?.data);
      navigate(-1);
    } if (isError && error) {
      errorSnackBar({ message: error?.data?.errorMessage });
    }
  }, [isSuccess, isError, data, error]);

  if (itemLoading) {
    return <TuiSpinner />;
  }

  return (
    <Grid
      component='form'
      onSubmit={handleSubmit((userInput) => {
        userInput.startDate = moment(userInput.startDate).startOf('day').valueOf();
        userInput.endDate = moment(userInput.endDate).endOf('day').valueOf();
        if (!userInput.route?.id) {
          userInput.route = {
            id: null,
          };
        }
        updateTiffinSubscriber({ id, data: userInput });
      })}>
      <Grid container spacing={1}>
        <Grid size={12}>
          <TuiAppBar title='Edit Customer' />
        </Grid>
        <Grid
          direction="column"
          sx={{ mt: 1, flex: 1, overflowY: 'auto' }}
          container
        >
          <CustomerForm
            action='update'
            setValue={setValue}
            getValues={getValues}
            control={control}
            register={register}
            errors={errors}
            viewOnly={false}
            watch={watch}
            item={fetchedData?.data}
            isDirty={isDirty}
            isLoading={isLoading}
          />
        </Grid>
      </Grid>
      <Footer>
        <Grid
          container
          justifyContent='flex-end'
          size={12}
          spacing={2}
          sx={{ p: 1 }}
        >
          <Grid size='auto'>
            {isDirty && <Alert severity="warning" sx={{ p: 0, pl: 1, pr: 1 }}>
              <Typography variant="body2">
                {`OPTIMIZE the routes to reflect changes in today's packing list, stickers, and routes.`}
              </Typography>
            </Alert>}
          </Grid>
          <Grid size='auto'>
            <CoreButton
              startIcon={<ArrowBackIosNewOutlinedIcon />}
              fullWidth={false}
              variant='outlined'
              onClick={() => navigate(-1)}
            >
              Back
            </CoreButton>
          </Grid>
          <Grid size='auto'>
            <CoreButton
              startIcon={<SaveOutlinedIcon />}
              type='submit'
              fullWidth={false}
              variant='contained'
              isLoading={isLoading}
              disabled={!isDirty}
            >
              Save
            </CoreButton>
          </Grid>
        </Grid>
      </Footer>
    </Grid>
  );
}
