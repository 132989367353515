import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Grid2 as Grid } from '@mui/material';
import moment from 'moment-timezone';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import { useReactToPrint } from 'react-to-print';
import DinespotLogo from '../../../assets/images/dinespot_black_logo.png';

import { stopsApi, deliveryRoutesApi } from '../../../js/slices/api_slices';
import { getFormattedDateFromUnixTimestamp, getTimezone , capitalizeFirstLetter} from '../../../js/lib/utils';
import { TuiAppBar, CoreButton, Hooks } from '../../../core';
import TiffinStickers from '../components/stickers';

const { useLazyGetStopsQuery } = stopsApi;
const { useLazyGetDeliveryRoutesQuery } = deliveryRoutesApi;
const { useSnackBarNotification } = Hooks;

export default function TiffinsPackingDetails() {
  const stickersRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const settings = useSelector((store) => store?.settings.settings);
  const business = useSelector((store) => store?.business?.business);
  const [getStopsTrigger] = useLazyGetStopsQuery({});
  const [getRoutesTrigger] = useLazyGetDeliveryRoutesQuery({});
  const { successSnackBar } = useSnackBarNotification();
  const [routeWithStopsForStickers, setRouteWithStopsForStickers] = useState([]);
  const timezone = getTimezone(settings?.timezone);
  const selectedDate = moment().tz(timezone);

  const fetchAllStopsFromApi = async (page = 1, pageSize = 200, id) => {
    let data = [];
    let totalPages = 0;
    let currentPage = page;

    do {
      const response = await getStopsTrigger({
        'page': currentPage,
        'pageSize': pageSize,
        'routeId': id,
        'sortKey': '+position',
      });
      const responseData = response?.data?.data;
      if (currentPage === page) {
        data = responseData.items;
        const totalCount = responseData.totalCount;
        totalPages = Math.ceil(totalCount / pageSize);
      } else {
        data = [...data, ...responseData.items];
      }
      currentPage++;
    } while (currentPage <= totalPages);

    return { items: data, totalCount: data.length };
  };

  const fetchAllRoutesFromApi = async (page = 1, pageSize = 200) => {
    let data = [];
    let totalPages = 0;
    let currentPage = page;

    do {
      const response = await getRoutesTrigger({
        'page': currentPage,
        'pageSize': pageSize,
        'sortKey': '+name',
        startTimeFrom: moment().startOf('day').valueOf(),
      });
      const responseData = response?.data?.data;
      if (currentPage === page) {
        data = responseData.items;
        const totalCount = responseData.totalCount;
        totalPages = Math.ceil(totalCount / pageSize);
      } else {
        data = [...data, ...responseData.items];
      }
      currentPage++;
    } while (currentPage <= totalPages);

    return { items: data, totalCount: data.length };
  };

  const fetchAllRoutesWithStops = async () => {
    const routes = await fetchAllRoutesFromApi();
    let routeWithStopsResult = [];
    for (let route of routes?.items) {
      const routeClone = { ...route };
      const data = await fetchAllStopsFromApi(1, 200, route?.id);
      routeClone.stops = data?.items;
      routeWithStopsResult.push(routeClone);
    }
    setRouteWithStopsForStickers(routeWithStopsResult.flatMap((item) => item?.stops || []));
    return Promise.resolve(routeWithStopsResult);
  };

  const handlePrint = useReactToPrint({
    contentRef: stickersRef,
  });

  const handlePackingListDownload = async () => {
    setLoading(true);
    const routeWithStops = await fetchAllRoutesWithStops();
    if (routeWithStops?.length) {
      const doc = new jsPDF();
      doc.setFontSize(16);
      doc.setTextColor(128, 128, 128);
      const text = `Packing List: ${getFormattedDateFromUnixTimestamp(selectedDate)}`;
      const x = 105;
      const y = 10;
      doc.text(text, x, y, { align: 'center' });
      const textWidth = doc.getTextWidth(text);
      const underlineY = y + 1;
      doc.setDrawColor(128, 128, 128);
      doc.setLineWidth(0.5);
      doc.line(x - textWidth / 2, underlineY, x + textWidth / 2, underlineY);

      routeWithStops.forEach((route, routeIndex) => {
        if (routeIndex !== 0) {
          if (route?.stopCount <= 0) {
            return;
          }
          doc.addPage();
        }

        doc.setFontSize(18);
        doc.setFont('helvetica', 'bold');
        const routeName = `Route name: ${route?.name}`;
        const x = 105;
        const y = routeIndex === 0 ? 20 : 10;
        doc.text(routeName, x, y, { align: 'center' });
        const textWidth = doc.getTextWidth(routeName);
        const underlineY = y + 1;
        doc.setDrawColor(0, 0, 0);
        doc.setLineWidth(0.5);
        doc.line(x - textWidth / 2, underlineY, x + textWidth / 2, underlineY);

        const tiffinData = {};
        const cookingPlanData = {};
        const tableData = [];
        route.stops.forEach((stop, stopIndex) => {
          const { customer } = stop;
          const { tiffin } = customer;
          const { items, changedItems } = tiffin;
          if (tiffinData[tiffin?.name]) {
            tiffinData[tiffin?.name] = tiffinData[tiffin?.name] + 1;
          } else {
            tiffinData[tiffin?.name] = 1;
          }

          items.forEach((item) => {
            const key = `${item?.name.toLowerCase()}_${item?.unit}`;
            if (cookingPlanData[key]) {
              cookingPlanData[key]['quantity'] = cookingPlanData[key]['quantity'] + item?.quantity;
            } else {
              cookingPlanData[key] = {
                name: capitalizeFirstLetter(item?.name),
                quantity: item?.quantity,
                unit: item?.unit,
              };
            }
          });

          tableData.push([
            stopIndex + 1,
            `${customer?.firstName} ${customer?.lastName}`,
            tiffin?.name,
            changedItems?.length
              ? [...changedItems]
                .sort((a, b) => a.status.localeCompare(b.status))
                .map(item => `${item?.status} - ${item?.name} (${item?.quantity} ${item?.unit?.toLowerCase()})`)
                .join('\n')
              : '',
            customer?.comment
          ]);
        });

        tiffinData['Total'] = route?.stopCount;

        const tiffinDataHeaders = ['Tiffin Name', 'Quantity'];
        autoTable(doc, {
          theme: 'grid',
          columnStyles: { 0: { cellWidth: 50 }, 1: { cellWidth: 20 } },
          head: [tiffinDataHeaders],
          body: tiffinData ? Object.keys(tiffinData).map((key) => [key, tiffinData[key]]) : [],
          startY: routeIndex === 0 ? 35 : 25,
          margin: { left: 10, bottom: 15 },
        });

        const cookingPlanHeaders = ['Name', 'Quantity', 'Unit'];
        autoTable(doc, {
          theme: 'grid',
          columnStyles: { 0: { cellWidth: 60 }, 1: { cellWidth: 20 }, 2: { cellWidth: 30 } },
          head: [cookingPlanHeaders],
          body: cookingPlanData ? Object.values(cookingPlanData).map(({name, quantity, unit}) => [name, quantity, unit]) : [],
          startY: routeIndex === 0 ? 35 : 25,
          margin: { left: 95, right: 10, bottom: 15 },
        });

        doc.addPage();
        const headers = ['#', 'Name', 'Tiffin', 'Item Changes', 'Comment'];
        autoTable(doc, {
          theme: 'grid',
          columnStyles: { 0: { cellWidth: 10 }, 1: { cellWidth: 50 }, 2: { cellWidth: 30 }, 3: { cellWidth: 50 }, 4: { cellWidth: 50 } },
          head: [headers],
          body: tableData,
          margin: { left: 10, right: 10, bottom: 15 },
        });
      });

      doc.setFontSize(8);
      for (let i = 1; i <= doc.getNumberOfPages(); i++) {
        doc.setPage(i);
        var img = new Image();
        img.src = DinespotLogo;
        doc.addImage(img, 'PNG', 10, 285, 20, 5);
        doc.text(185, 288, `Page ${i} / ${doc.getNumberOfPages()}`);
      }

      const businessName = business?.name.replace(/\s+/g, '_');
      const today = getFormattedDateFromUnixTimestamp(selectedDate).replace(/\s+/g, '_');
      doc.save(`${businessName}_packing_list_${today}.pdf`);
    } else {
      successSnackBar({ message: 'No route found for the packing list.' });
    }
    setLoading(false);
  };

  return (
    <>
      <Grid container sx={{ height: '50vh' }}>
        <Grid container size={12}>
          <Grid size={12}>
            <TuiAppBar
              title={`Packing List: ${getFormattedDateFromUnixTimestamp(selectedDate)}`}
            />
          </Grid>
        </Grid>
        <Grid size={12} container direction='column' justifyContent='center' alignItems='center' spacing={2}>
          <Grid size="auto">
            <CoreButton
              isLoading={loading}
              fullWidth={false}
              disabled={false}
              variant='contained'
              startIcon={<DownloadOutlinedIcon />}
              onClickHandler={handlePackingListDownload}
            >
              Packing List
            </CoreButton>
          </Grid>
          <Grid size="auto">
            <CoreButton
              disabled={false}
              isLoading={loading}
              variant='outlined'
              startIcon={<DownloadOutlinedIcon />}
              onClickHandler={async () => {
                setLoading(true);
                if (routeWithStopsForStickers.length === 0) {
                  const result = await fetchAllRoutesWithStops();
                  if (result.length === 0) {
                    successSnackBar({ message: 'No route found for the stickers.' });
                    setLoading(false);
                    return;
                  }
                }
                setLoading(false);
                setTimeout(() => handlePrint(), 500);
              }}
            >
              Stickers
            </CoreButton>
          </Grid>
        </Grid>
      </Grid >
      {!loading && <div style={{ display: 'none' }}>
        <TiffinStickers ref={stickersRef} data={routeWithStopsForStickers} />
      </div>
      }
    </>
  );
}
