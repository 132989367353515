import React from 'react';
import { Route, Routes } from 'react-router-dom';

import HomePageComponent from './components/homepage_component';
import ContentNotFound from '../errors/components/content_not_found';
import Employees from '../employees';
import Settings from '../settings';
import Tiffins from '../tiffin';
import DeliveryRoutes from '../routes';
import CustomerRoutes from '../customers';
import CookingPlan from '../cooking_plan';
import PackingDetails from '../packing';
import Report from '../reports';
import Expenses from '../expenses';
import Leads from '../leads';

export default function HomePage() {
  return (
    <Routes>
      <Route path="/*" element={<HomePageComponent />} >
        <Route path='employees/*' element={<Employees />} />
        <Route path='settings/*' element={<Settings />} />
        <Route path='meal-plans/*' element={<Tiffins />} />
        <Route path='customers/*' element={<CustomerRoutes />} />
        <Route path='cooking-plan/*' element={<CookingPlan />} />
        <Route path='packing/*' element={<PackingDetails />} />
        <Route path='routes/*' element={<DeliveryRoutes />} />
        <Route path='reports/*' element={<Report />} />
        <Route path='expenses/*' element={<Expenses />} />
        <Route path='leads/*' element={<Leads />} />
        <Route path="*" element={<ContentNotFound />} />
      </Route>
    </Routes>
  );
}
