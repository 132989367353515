import React, { useEffect } from 'react';
import moment from 'moment';
import { Grid2 as Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';

import CustomerForm from '../components/customer_form';
import { Footer } from '../../../core';
import { tiffinsApi } from '../../../js/slices/api_slices';
import { TIFFIN, DELIVERY_METHOD, STORAGE_KEYS } from '../../../js/lib/constants';

import { TuiAppBar, Hooks, CoreButton } from '../../../core/index';

const { useSnackBarNotification, useSessionStoragePersist } = Hooks;
const { useCreateTiffinSubscriberMutation } = tiffinsApi;

export default function CreateCustomerView() {
  const navigate = useNavigate();
  const settings = useSelector((store) => store?.settings?.settings);

  let initialData = sessionStorage.getItem(STORAGE_KEYS.CUSTOMER);
  if (initialData) {
    try {
      let initialJsonData = JSON.parse(initialData);
      initialData = initialJsonData;
    } catch (err) {
      console.error('Error parsing initialData', err);
    }
  } else {
    initialData = {
      shipping: DELIVERY_METHOD.HOME_DELIVERY,
      deliveryDays: settings?.deliveryDays,
      tiffin: {
        name: '',
        plan: {
          type: TIFFIN.PLANS.MONTHLY,
          count: 1,
          price: 0,
        },
      },
      route: {
        id: null,
      },
    }
  }

  const { register, handleSubmit, formState: { errors, isDirty }, control, watch, getValues, setValue, reset } = useForm({
    defaultValues: initialData,
    mode: 'onChange',
  });

  const { errorSnackBar, successSnackBar } = useSnackBarNotification();
  const [addNewSubscriber, {
    data,
    isSuccess,
    error,
    isError,
    isLoading,
  }] = useCreateTiffinSubscriberMutation();

  useEffect(() => {
    if (isSuccess && data) {
      successSnackBar({ message: 'Customer created successfully' });
      navigate(-1);
    } if (isError && error) {
      errorSnackBar({ message: error?.data?.errorMessage });
    }
    return () => {
      if (isSuccess) {
        sessionStorage.removeItem(STORAGE_KEYS.CUSTOMER);
      }
    }
  }, [isSuccess, isError, data, error]);

  useSessionStoragePersist({ value: watch(), sessionStorageKey: STORAGE_KEYS.CUSTOMER });

  return (
    <Grid           
    component='form'
    onSubmit={handleSubmit((userInput) => {
      userInput.startDate = moment(userInput.startDate).startOf('day').valueOf();
      userInput.endDate = moment(userInput.endDate).endOf('day').valueOf();
      if (!userInput.route?.id) {
        userInput.route = {
          id: null,
        };
      }
      addNewSubscriber(userInput);
    })}>
      <Grid container spacing={1}>
        <Grid size={12}>
          <TuiAppBar title='New Customer' />
        </Grid>
        <Grid
          direction="column"
          sx={{ mt: 1, flex: 1, overflowY: 'auto' }}
          container
        >
          <CustomerForm
            action='create'
            setValue={setValue}
            getValues={getValues}
            control={control}
            register={register}
            errors={errors}
            viewOnly={false}
            watch={watch}
            isDirty={isDirty}
            isLoading={isLoading}
          />
        </Grid>
      </Grid>
      <Footer>
        <Grid
          container
          justifyContent='flex-end'
          size={12}
          spacing={2}
          sx={{pr: 2}}
        >
          <Grid size='auto'>
              <CoreButton
                startIcon={<ArrowBackIosNewOutlinedIcon />}
                fullWidth={false}
                variant='outlined'
                onClick={() => navigate(-1)}
              >
                Back
              </CoreButton>
            </Grid>
            <Grid size='auto'>
              <CoreButton
                startIcon={<SaveOutlinedIcon />}
                type='submit'
                fullWidth={false}
                variant='contained'
                isLoading={isLoading}
              >
                Save
              </CoreButton>
            </Grid>
        </Grid>
      </Footer>
    </Grid>
  );
}
