import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import moment from 'moment';
import {
    Grid2 as Grid,
} from '@mui/material';

import { BaseTextField, DatePicker } from '../../../core/index';

export default function LeadForm() {
    const { register, control, formState: { errors } } = useFormContext();
    return <Grid
        container
        size={12}
        justifyContent="center"
        sx={{
            height: '100%',
            p: 2,
        }}>
        <Grid size={6} container spacing={3} sx={{
            p: 4,
            mt: 1,
            backgroundColor: 'white',
            border: '1px solid #e0e0e0',
            borderColor: 'grey.300',
            borderRadius: '5px',
        }}>
            <Grid size={12} container spacing={1}>
                <Grid size={6}>
                    <BaseTextField
                        id='firstName'
                        name="firstName"
                        label="First Name"
                        errors={errors}
                        required={true}
                        validate={register('firstName', {
                            required: 'Name must be valid',
                            maxLength: {
                                value: 100,
                                message: 'Name must be less than 100 characters'
                            },
                        })}
                    />
                </Grid>
                <Grid size={6}>
                    <BaseTextField
                        id='lastName'
                        name="lastName"
                        label="Last Name"
                        errors={errors}
                        required={false}
                        validate={register('lastName', {
                            required: 'Last Name must be valid',
                            maxLength: {
                                value: 100,
                                message: 'Last Name must be less than 100 characters'
                            },
                        })}
                    />
                </Grid>
            </Grid>
            <Grid size={12} container spacing={1}>
                <Grid size={6}>
                    <BaseTextField
                        id='phoneNumber.number'
                        name="'phoneNumber.number"
                        label="Phone Number"
                        errors={errors}
                        required={true}
                        validate={register('phoneNumber.number', {
                            required: 'Phone Number must be valid',
                            maxLength: {
                                value: 20,
                                message: 'Phone Number must be less than 20 characters'
                            },
                        })}
                    />
                </Grid>
                <Grid size={6}>
                    <BaseTextField
                        id='email'
                        name="email"
                        label="Email"
                        errors={errors}
                        required={false}
                        validate={register('email', {
                            required: 'Email must be valid',
                            maxLength: {
                                value: 100,
                                message: 'Email must be less than 100 characters'
                            },
                        })}
                    />
                </Grid>
            </Grid>
            <Grid size={12} container spacing={1}>
                <Grid size={6}>
                    <Controller
                        control={control}
                        rules={{ required: 'Invalid Start Date' }}
                        name="startDate"
                        render={({ field: { onChange, value } }) => {
                            return (
                                <DatePicker
                                    control={control}
                                    onChange={(event) => {
                                        onChange(event);
                                    }}
                                    required={true}
                                    errors={errors}
                                    label="Date"
                                    value={value ? moment(value) : null}
                                />
                            );
                        }
                        }
                    />
                </Grid>
                <Grid size={6}>
                    <BaseTextField
                        id='amount'
                        name="amount"
                        label="Price"
                        errors={errors}
                        required={true}
                        type='number'
                        validate={register('amount', {
                            required: 'Price must be valid',
                            min: {
                                value: 0,
                                message: 'Price must be greater than 0'
                            },
                            max: {
                                value: 1000000,
                                message: 'Price must be less than 1000000'
                            }
                        })}
                    />
                </Grid>
            </Grid>
        </Grid>
    </Grid>
}
