import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import {
  Modal, Grid, IconButton, InputAdornment,
  Typography, FormControlLabel, Checkbox, Select, Box, OutlinedInput, MenuItem, Chip, Divider, Radio, RadioGroup
} from '@mui/material';
import { CoreButton } from '../../../core';
import ClearIcon from '@mui/icons-material/Clear';
import { DELIVERY_METHOD, TIFFIN, STORAGE_KEYS, DAYS } from '../../../js/lib/constants';
import { getTimezone } from '../../../js/lib/utils';
import { tiffinsApi } from '../../../js/slices/api_slices';

function getStyles(name, routeName, theme) {
  return {
    fontWeight:
      routeName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  p: 2,
};

const { useLazyGetTiffinsQuery } = tiffinsApi;
const showAllTiffinsText = 'All';
const showSpecialTiffinsText = 'Special';
const showNonSpecialTiffinsText = 'Non Special';
const specialTiffinsRadioOptions = [showAllTiffinsText, showSpecialTiffinsText, showNonSpecialTiffinsText];

export default function CustomerFilterModal({ open, handleClose, setFilterCount, setPage, filterObject, setFilterObject, data }) {
  const statusFilter = filterObject?.status ? new Set(filterObject?.status.split(',')) : new Set();
  const [paymentFilter, setPaymentFilter] = useState(filterObject?.payment ? new Set(filterObject?.payment.split(',')) : new Set());
  const [deliveryMethodFilter, setDeliveryMethodFilter] = useState(filterObject?.shipping ? new Set(filterObject?.shipping.split(',')) : new Set());
  const [daysFilterValues, setDaysFilterValues] = useState(filterObject?.deliveryDays?.length > 0 ? DAYS.filter(({ value }) => filterObject?.deliveryDays?.includes(value)).map((item) => item?.shortName) : []);
  const [routeFilter, setRouteFilter] = useState(filterObject?.route ? new Set(filterObject?.route.split(',')) : new Set());
  const [tiffinFilter, setTiffinFilter] = useState(filterObject?.tiffin ? new Set(filterObject?.tiffin.split(',')) : new Set());
  const settings = useSelector((store) => store?.settings?.settings);
  const timezone = getTimezone(settings?.timezone);
  const theme = useTheme();
  const [routeName, setRouteName] = useState([]);
  const [tiffins, setTiffins] = useState([]);
  const [tiffinsFilterValues, setTiffinFilterValues] = useState([]);
  const [itemsChangedFilter, setItemsChangedFilter] = useState(filterObject.itemsChanged || showAllTiffinsText);
  const [getTiffinTrigger] = useLazyGetTiffinsQuery({});

  const fetchAllTIffins = async (page = 1, pageSize = 200) => {
    const response = await getTiffinTrigger({
      "page": page,
      "pageSize": pageSize,
    });

    let data = response?.data?.data;
    const totalCount = data.totalCount;
    const totalPages = Math.ceil(totalCount / pageSize);

    if (page < totalPages) {
      const nextPageData = await fetchAllTIffins(page + 1, pageSize);
      data = { ...data, items: [...data.items, ...nextPageData.items] };
    }

    return data;
  };

  useEffect(() => {
    if (open) {
      fetchAllTIffins().then((data) => {
        setTiffins(data?.items);
      });
    }
  }, [open]);

  useEffect(() => {
    if (tiffinFilter.size > 0) {
      setTiffinFilterValues(Array.from(tiffinFilter).map((id) => tiffins?.find((tiffin) => tiffin.id === id)?.name));
    }
  }, [tiffinFilter, tiffins]);

  useEffect(() => {
    if (routeFilter.size > 0) {
      setRouteName(Array.from(routeFilter).map((id) => data?.routeData?.find((route) => route.id === id)?.label));
    }
  }, [routeFilter, data?.routeData]);

  useEffect(() => {
    setFilterCount(
      deliveryMethodFilter.size +
      routeFilter.size +
      tiffinFilter.size +
      paymentFilter.size +
      (daysFilterValues.length > 0 ? 1 : 0) +
      (itemsChangedFilter === showAllTiffinsText ? 0 : 1),
    );
  }, []);

  const handleRouteChange = (event) => {
    const { target: { value } } = event;
    setRouteName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
    const routeIds = value.map((item) => data?.routeData?.find((route) => route.label === item)?.id);
    setRouteFilter(new Set(routeIds));
  };

  const handleTiffinFilterChange = (event) => {
    const { target: { value } } = event;
    setTiffinFilterValues(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
    const tiffinIds = value.map((item) => tiffins?.find((tiffin) => tiffin.name === item)?.id);
    setTiffinFilter(new Set(tiffinIds));
  };

  const handleDaysChange = (event) => {
    const { target: { value } } = event;
    setDaysFilterValues(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  return (
    <Modal
      open={open}
    >
      <Grid
        container
        spacing={1}
        sx={style}>
        <Grid item xs={12} container>
          <Typography variant='h6' fontWeight="bold">Filters</Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={4} container alignItems="center">
          <Typography variant='h7'>Meal Plans</Typography>
        </Grid>
        <Grid item xs={8}>
          <Select
            fullWidth
            id="tiffin-multiple-chip-tiffins"
            multiple
            size='small'
            value={tiffinsFilterValues}
            onChange={handleTiffinFilterChange}
            input={tiffinsFilterValues?.length && <OutlinedInput
              label="Chip"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={() => {
                    setTiffinFilter(new Set());
                    setTiffinFilterValues([]);
                  }} edge="end" size='small'>
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              }
              sx={{ paddingRight: '30px' }}
            />
            }

            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip key={value} label={value} />
                ))}
              </Box>
            )}
            MenuProps={MenuProps}
          >
            {tiffins?.map(({ id, name }) => (
              <MenuItem
                key={id}
                value={name}
                style={getStyles(name, tiffinsFilterValues, theme)}
              >
                {name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} container>
          <Grid item xs={4} container alignItems="center">
            <Typography variant='h7'>Delivery Days</Typography>
          </Grid>
          <Grid item xs={8} container>
            <Select
              fullWidth
              id="days-multiple-chip"
              multiple
              size='small'
              value={daysFilterValues}
              onChange={handleDaysChange}
              displayEmpty
              input={daysFilterValues?.length && <OutlinedInput
                label="Chip"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton onClick={() => setDaysFilterValues([])} edge="end" size='small'>
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                }
                sx={{ paddingRight: '30px' }}
              />
              }
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected
                    .map((item) => DAYS.find((day) => day.shortName === item))
                    .sort((a, b) => a.value - b.value)
                    .map((item) => (
                      <Chip key={item?.shortName} label={item?.shortName} />
                    ))}
                </Box>
              )}
              MenuProps={MenuProps}
            >
              {DAYS?.map(({ value, shortName }) => (
                <MenuItem
                  id={value}
                  key={value}
                  value={shortName}
                  style={getStyles(shortName, daysFilterValues, theme)}
                >
                  {shortName}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} container>
          <Grid item xs={4} container alignItems="center">
            <Typography variant='h7'>Delivery Type</Typography>
          </Grid>
          <Grid item xs={8} container>
            {Object.values(DELIVERY_METHOD).map((item) => <FormControlLabel key={item} control={<Checkbox
              sx={{
                '&, &.Mui-checked': {
                  color: 'secondary.contrastText',
                },
              }}
              checked={deliveryMethodFilter.has(item)}
              onChange={(e) => {
                if (e.target.checked) {
                  deliveryMethodFilter.add(item);
                  setDeliveryMethodFilter(new Set(deliveryMethodFilter));
                } else {
                  deliveryMethodFilter.delete(item);
                  setDeliveryMethodFilter(new Set(deliveryMethodFilter));
                }
              }}
            />} label={item} />)}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} container>
          <Grid item xs={4} container alignItems="center">
            <Typography variant='h7'>Routes</Typography>
          </Grid>
          <Grid item xs={8}>
            <Select
              fullWidth
              id="route-multiple-chip"
              multiple
              size='small'
              value={routeName}
              onChange={handleRouteChange}
              input={routeName?.length && <OutlinedInput
                label="Chip"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton onClick={() => {
                      setRouteName([]);
                      setRouteFilter(new Set());
                    }} edge="end" size='small'>
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                }
                sx={{ paddingRight: '30px' }}
              />
              }
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
              MenuProps={MenuProps}
            >
              {data?.routeData?.map(({ id, label }) => (
                <MenuItem
                  key={id}
                  value={label}
                  style={getStyles(label, routeName, theme)}
                >
                  {label}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} container id='special-tiffin-filter'>
          <Grid item xs={4} container alignItems="center">
            <Typography variant='h7'>Special Meals</Typography>
          </Grid>
          <Grid item xs={8} container>
            <RadioGroup
              row
              value={itemsChangedFilter}
              onChange={(e) => {
                const selectedValue = e.target.value;
                setItemsChangedFilter(selectedValue);
              }}
            >
              {specialTiffinsRadioOptions.map((item) => (
                <FormControlLabel
                  key={item}
                  control={<Radio
                    sx={{
                      '&, &.Mui-checked': {
                        color: 'secondary.contrastText',
                      },
                    }}
                  />}
                  value={item}
                  label={item}
                />
              ))}
            </RadioGroup>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} container id='payment-tiffin-filter'>
          <Grid item xs={4} container alignItems="center">
            <Typography variant='h7'>Payments</Typography>
          </Grid>
          <Grid item xs={8} container>
            {Object.values(TIFFIN.PAYMENTS).map((item) => <FormControlLabel key={item} control={<Checkbox
              sx={{
                '&, &.Mui-checked': {
                  color: 'secondary.contrastText',
                },
              }}
              checked={paymentFilter?.has(item)}
              onChange={(e) => {
                if (e.target.checked) {
                  paymentFilter.add(item);
                  setPaymentFilter(new Set(paymentFilter));
                } else {
                  paymentFilter.delete(item);
                  setPaymentFilter(new Set(paymentFilter));
                }
              }}
            />} label={item} />)}
          </Grid>
        </Grid>
        <Grid item xs={12} container sx={{ mt: 4 }}>
          <Grid item xs={4} container>
            <CoreButton
              disableRipple
              fullWidth={false}
              sx={{
                color: 'secondary.contrastText',
                textDecoration: 'underline',
              }}
              onClickHandler={() => {
                setDeliveryMethodFilter(new Set());
                setRouteFilter(new Set());
                setTiffinFilter(new Set());
                setTiffinFilterValues([]);
                setItemsChangedFilter(showAllTiffinsText);
                setPaymentFilter(new Set());
                setRouteName([]);
                setDaysFilterValues([]);
                setFilterCount(0);
                sessionStorage.removeItem(STORAGE_KEYS.CUSTOMER_FILTER);
              }}
            >
              Clear All Filters
            </CoreButton>
          </Grid>
          <Grid item xs={8} container justifyContent="flex-end" spacing={1}>
            <Grid item xs={6}>
              <CoreButton
                variant='outlined'
                onClickHandler={() => {
                  const existingObject = JSON.parse(sessionStorage.getItem(STORAGE_KEYS.CUSTOMER_FILTER));
                  if (existingObject) {
                    setPaymentFilter(new Set(existingObject?.paymentStatus?.split(',')));
                    setDeliveryMethodFilter(new Set(existingObject?.shipping?.split(',')));
                    setDaysFilterValues(existingObject?.deliveryDays || []);
                    setRouteFilter(new Set(existingObject?.route?.split(',')));
                    setTiffinFilter(new Set(existingObject?.tiffin?.split(',')));
                    setItemsChangedFilter(existingObject?.itemsChanged || showAllTiffinsText);
                  }
                  handleClose();
                }}
                sx={{}}
              >
                Cancel
              </CoreButton>
            </Grid>
            <Grid item xs={6}>
              <CoreButton
                variant='contained'
                onClickHandler={() => {
                  const filterObject = {
                    status: Array.from(statusFilter)?.join(','),
                    shipping: Array.from(deliveryMethodFilter)?.join(','),
                  };
                  if (routeFilter.size > 0) {
                    filterObject.route = Array.from(routeFilter)?.join(',');
                  }
                  if (tiffinFilter.size > 0) {
                    filterObject.tiffin = Array.from(tiffinFilter)?.join(',');
                  }

                  if (paymentFilter.size > 0) {
                    filterObject.paymentStatus = Array.from(paymentFilter)?.join(',');
                  }

                  filterObject.itemsChanged = itemsChangedFilter;

                  if (daysFilterValues.length > 0) {
                    filterObject.deliveryDays = DAYS.filter(({ shortName }) => daysFilterValues?.includes(shortName)).map(({ value }) => value);
                  }

                  setFilterObject(filterObject);

                  const filterCount =
                    paymentFilter.size +
                    deliveryMethodFilter.size +
                    daysFilterValues.length +
                    routeFilter.size +
                    tiffinFilter.size +
                    (itemsChangedFilter === showAllTiffinsText ? 0 : 1);

                  setFilterCount(filterCount);
                  sessionStorage.setItem(STORAGE_KEYS.CUSTOMER_FILTER, JSON.stringify(filterObject));
                  setPage(1);
                  handleClose();
                }}
              >
                Apply Filters
              </CoreButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Modal >
  );
}
