import React, { useState, useEffect } from 'react';
import { Grid, Typography, IconButton, Menu, MenuItem, ListItemIcon } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIconOutlined from '@mui/icons-material/DeleteOutlined';
import EditIconOutlined from '@mui/icons-material/EditOutlined';
import BlockIconOutlined from '@mui/icons-material/BlockOutlined';
import HowToRegIconOutlined from '@mui/icons-material/HowToRegOutlined';
import { USERS } from '../../../js/lib/constants';
import { employeesApi } from '../../../js/slices/api_slices';
import { Hooks, Table, ConfirmationModal, TuiSpinner } from '../../../core/index';
import { getStatusChip } from '../employees_utils';
import { getFormattedPhoneNumber } from '../../../js/lib/utils';

const { useDeleteEmployeeMutation, useUpdateEmployeeMutation } = employeesApi;

const headCells = [{
  id: 'name',
  title: 'Name',
}, {
  id: 'status',
  title: 'Status',
}, {
  id: 'type',
  title: 'Role',
}, {
  id: 'address',
  title: 'Address',
}, {
  id: 'phoneNumber',
  title: 'Phone Number',
}, {
  id: 'email',
  title: 'Email',
}, {
  id: 'action',
  title: 'Actions',
}];

export default function EmployeeListComponent({ items, setItems, isLoading, setPage, page }) {
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState(null);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
  const [showBlockConfirmationModal, setShowBlockConfirmationModal] = useState(false);
  const [showActiveConfirmationModal, setShowActiveConfirmationModal] = useState(false);
  const { errorSnackBar, successSnackBar } = Hooks.useSnackBarNotification();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  const handleClose = (e) => {
    setAnchorEl(null);
    e.stopPropagation();
  };

  const [updateEmployee, {
    data: updateEmployeeData,
    isSuccess: updateEmployeeIsSuccess,
    error: updateEmployeeError,
    isError: updateEmployeeIsError,
    isLoading: updateEmployeeLoading,
  }] = useUpdateEmployeeMutation();

  const [deleteEmployee, {
    data: deleteData,
    isSuccess: deleteIsSuccess,
    error: deleteError,
    isError: deleteIsError,
    isLoading: deleteIsLoading,
  }] = useDeleteEmployeeMutation();


  useEffect(() => {
    if (deleteIsSuccess && deleteData) {
      successSnackBar({ message: 'Employee deleted successfully' });
      const newItems = items.filter((item) => item.id !== deleteData?.data?.id);
      if (!newItems.length && Number(page) > 1) {
        setPage(page - 1);
      }
      setItems(newItems);
    } if (deleteError && deleteIsError) {
      errorSnackBar({ message: deleteError?.data?.errorMessage });
    }
  }, [deleteIsSuccess, deleteError, deleteIsError]);

  useEffect(() => {
    if (updateEmployeeIsSuccess && updateEmployeeData) {
      setItems((items) => items.map((item) => item?.id === updateEmployeeData?.data?.id ? updateEmployeeData?.data : item));
      successSnackBar({ message: 'Employee updated successfully' });
    } if (updateEmployeeError && updateEmployeeIsError) {
      errorSnackBar({ message: updateEmployeeError?.data?.errorMessage });
    }
  }, [updateEmployeeIsSuccess, updateEmployeeError, updateEmployeeIsError]);

  const deleteConfirmationModal = <ConfirmationModal
    open={showDeleteConfirmationModal}
    handleClose={() => setShowDeleteConfirmationModal(false)}
    title={<Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant='h6'>{`Are you sure want to delete this employee ?`}</Typography>
      </Grid>
    </Grid>}
    type="Delete"
    actionHandler={() => {
      setShowDeleteConfirmationModal(false);
      deleteEmployee({ id: selectedItem?.id });
    }}
  />;

  const blockedUserConfirmationModal = <ConfirmationModal
    open={showBlockConfirmationModal}
    handleClose={() => setShowBlockConfirmationModal(false)}
    title={<Typography variant="h6">
      Are you sure want to block this employee ?
    </Typography>}
    type="Block"
    actionHandler={() => {
      const data = { ...selectedItem, status: USERS.STATUS.BLOCKED };
      setShowBlockConfirmationModal(false);
      updateEmployee({ id: selectedItem?.id, data });
    }}
  />;

  const activeUserConfirmationModal = <ConfirmationModal
    open={showActiveConfirmationModal}
    handleClose={() => setShowActiveConfirmationModal(false)}
    title={<Typography variant="h6">
      Are you sure want to active this employee ?
    </Typography>}
    type="Active"
    actionHandler={() => {
      const data = { ...selectedItem, status: USERS.STATUS.ACTIVE };
      setShowActiveConfirmationModal(false);
      updateEmployee({ id: selectedItem?.id, data });
    }}
  />;

  const menuOptions = (item) => [{
    id: item.status === USERS.STATUS.BLOCKED ? 'active' : 'block',
    title: item.status === USERS.STATUS.BLOCKED ? 'Active' : 'Block',
    disabled: false,
    icon: item.status === USERS.STATUS.BLOCKED ? <HowToRegIconOutlined fontSize="small" /> : <BlockIconOutlined fontSize="small" />,
    action: () => {
      if (item.status === USERS.STATUS.BLOCKED) {
        setShowActiveConfirmationModal(true);
      } else {
        setShowBlockConfirmationModal(true);
      }
    }
  }, {
    id: 'delete',
    title: 'Delete',
    disabled: false,
    icon: <DeleteIconOutlined fontSize="small" />,
    action: () => {
      setShowDeleteConfirmationModal(true);
    }
  }];

  const processedItems = items?.map((item) => {
    const { firstName, lastName, id, status, type } = item;
    return {
      name: `${firstName} ${lastName}`,
      status: getStatusChip(status),
      type,
      address: item?.address?.description || '--',
      phoneNumber: getFormattedPhoneNumber(item?.phoneNumber?.number),
      email: item?.email,
      action: <div>
        <IconButton
          aria-label="more"
          id={`long-menu-id-${id}`}
          aria-controls={open ? `long-menu-${id}` : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={(event) => {
            setSelectedItem(item);
            handleClick(event);
            event.stopPropagation();
          }}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id={`long-menu-${id}`}
          MenuListProps={{
            'aria-labelledby': `long-button-${id}`,
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          {menuOptions(item).map(({ id, title, icon, action, disabled }) => (
            <MenuItem
              disabled={disabled}
              key={id}
              onClick={(e) => {
                action();
                handleClose(e);
              }}>
              <ListItemIcon>
                {icon}
              </ListItemIcon>
              {title}
            </MenuItem>
          ))}
        </Menu>
      </div>,
      id,
    };
  });

  if (deleteIsLoading || updateEmployeeLoading) {
    return <TuiSpinner />;
  }

  return (
    <>
      <Grid container>
        <Table
          headers={headCells}
          data={processedItems}
          isLoading={isLoading}
          setSelectedRow={(row) => {
            navigate(row?.id);
          }} />
      </Grid>
      {deleteConfirmationModal}
      {blockedUserConfirmationModal}
      {activeUserConfirmationModal}
    </>
  );
}
